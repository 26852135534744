import { darken } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  background: #f9f9f9;
  border-radius: 14px;

  div {
    svg,
    p {
      margin-bottom: 10px !important;
    }

    p {
      color: #4a7d5c;
      width: calc(100% - 35px);
    }

    span.plan-name {
      background: #aef7ac;
      border-radius: 18px;
      color: #2d2e2e;
    }

    button {
      background: #cfcfcf;

      span {
        color: #2d2e2e;
        margin-bottom: 0;
      }

      svg {
        margin-bottom: 0 !important;
      }

      :hover {
        background: ${darken(0.1, '#CFCFCF')};
      }
    }
  }

  @media screen and (min-width: 340px) {
    div {
      svg,
      p,
      span.plan-name {
        margin-bottom: 10px !important;
      }

      p {
        width: calc(100% - 123px);
      }
    }
  }

  @media screen and (min-width: 425px) {
    div {
      p {
        width: unset;
      }

      span.plan-name {
        margin-left: 10px;
      }
    }
  }

  @media screen and (min-width: 520px) {
    div {
      svg,
      p,
      span.plan-name {
        margin-bottom: 0px !important;
      }
    }
  }
`;
