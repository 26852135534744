import React, { useCallback, useRef } from 'react';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import getValidationsErrors from '~/utils/getValidationsErrors';

import { Container, Background } from './styles';
import Input from '~/components/Input';

interface IFormData {
  email: string;
}

const ResetPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(async (data: IFormData) => {
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        password: Yup.string().required('A senha é obrigatória'),
        password_confirmation: Yup.string().oneOf(
          [Yup.ref('password'), null],
          'Senhas não combinam'
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      // await api.post('users/sessions/forgot-password', {
      //   email: data.email.toLowerCase(),
      // });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationsErrors(error);
        formRef.current?.setErrors(errors);
      } else {
        Swal.fire(
          'Opss...',
          'Ocorreu um erro ao logar, verifique seus dados.',
          'error'
        );
      }
    }
  }, []);

  return (
    <Container className="container-fluid d-flex flex-column justify-content-center py-4 py-lg-0">
      <div className="row h-100">
        <Background className="d-none d-lg-block col-lg-6 ms-auto" />
        <div className="col-12 h-100">
          <div className="container h-100">
            <div className="row h-100 justify-content-end">
              <Form
                ref={formRef}
                onSubmit={handleSubmit}
                className="col-lg-6 align-self-center px-4 px-lg-3"
              >
                <div className="row justify-content-center">
                  <div className="col-lg-9 me-lg-5">
                    <h1 className="fw-semibold h2 mb-4">
                      Criar uma nova senha
                    </h1>
                    <h2 className="fw-normal mb-5 h5">Insira sua nova senha</h2>
                    <div className="row">
                      <div className="col-lg-10">
                        <label className="d-block w-100 py-3">
                          <span className="d-block mb-2">Nova senha</span>
                          <Input
                            type="password"
                            name="password"
                            placeholder="******"
                            className="input"
                          />
                        </label>
                        <label className="d-block w-100 py-3">
                          <span className="d-block mb-2">Confirmar senha</span>
                          <Input
                            type="password"
                            name="password_confirmation"
                            placeholder="******"
                            className="input"
                          />
                        </label>
                        <div className="pt-2 w-100">
                          <button
                            type="submit"
                            className="btn btn-submit d-block w-100 mt-5 fw-semibold mb-3"
                          >
                            Confirmar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
              <div className="col-lg-6 d-none d-lg-block" />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ResetPassword;
