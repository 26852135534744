import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';
import { lighten } from 'polished';

export const Container = styled.div`
  h2 {
    color: #202020;
  }

  .box {
    background: #ffffff;
    border: 1px solid #f3f3f3;
    box-shadow: 3px 4px 23px rgba(95, 95, 95, 0.1),
      inset 1px 1px 2px rgba(255, 255, 255, 0.3),
      inset -1px -1px 2px rgba(230, 230, 230, 0.5);
    border-radius: 18px;

    .plan-name {
      background: #aef7ac;
      color: #2d2e2e;
    }

    .duration {
      color: #000;
    }

    button {
      background: #f3f3f3;
      border-radius: 10px;
      color: #202020;
    }
  }

  .box.recommended {
    background: #303030;
    color: #a6a6a6;

    .alert-recommended {
      background: #fff961;
      color: #2d2e2e;
    }

    .plan-name {
      background: #72bd6f;
    }

    .duration {
      color: #fff;
    }

    .month {
      color: #a6a6a6;
    }

    .description {
      color: #e4e4e4;
    }

    button {
      background: linear-gradient(90deg, #0fdfb7 -44.4%, #aaf23c 130.97%);
      border-radius: 18px;
    }
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    border: none;
    border-radius: 10px;
    padding: 1.5rem;

    .close {
      font-size: 24px;
      border: none;
      position: absolute;
      top: 20px;
      right: 40px;
      font-size: 30px;
      background: none;

      .sr-only {
        display: none;
      }
    }

    .modal-title {
      color: #202020;
    }

    .btn-new {
      background: #ffffff;
      border: 1px solid #d9d9d9;
      border-radius: 10px;
      color: #202020;
    }

    form {
      .box {
        background: #ffffff;
        border: 1px solid #d9d9d9;
        border-radius: 10px;

        .input {
          background: #f0f0f0;
          border: 1px solid #e4e4e4 !important;
          border-radius: 8px;

          input {
            color: #202020;

            ::placeholder {
              color: #a4a4a4;
            }
          }
        }

        label {
          span {
            color: #000000;
          }

          .toggle {
            height: 27px;
            width: 62px;
            background: #efefef;
            border-radius: 50px;
            box-shadow: inset 0px 6px 8px 3px rgba(0, 0, 0, 0.1);
            border: 0;
            position: relative;
            cursor: pointer;
            transition-duration: 0.3s;

            :after {
              content: '';
              position: absolute;
              filter: drop-shadow(-2px 1px 6px rgba(0, 0, 0, 0.25));
              background: linear-gradient(180deg, #ffffff 0%, #e8eaea 100%);
              width: 21px;
              height: 21px;
              border-radius: 50%;
              transition-duration: 0.3s;
              top: 50%;
              left: calc(50% - 5px);
              transform: translate(-100%, -50%);
            }
          }

          .toggle.checked {
            background: #76ee59;

            :after {
              left: calc(100% - 5px);
            }
          }
        }

        .btn-submit {
          border: 1px solid #202020 !important;
          background-color: #202020 !important;
          color: #ffffff !important;

          :hover {
            border-color: ${lighten(0.1, '#202020')} !important;
            background-color: ${lighten(0.1, '#202020')} !important;
          }
        }

        .btn-edit {
          div {
            width: 30px;
            height: 30px;
            background: #b6b7b6;
            border-radius: 50%;
          }
        }

        .btn-delete {
          color: #e51810;

          div {
            width: 30px;
            height: 30px;
            background: #e51810;
            border-radius: 50%;
          }
        }
      }

      + form {
        margin-top: 26px;
      }
    }
  }
`;
