import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { TiStar, TiStarFullOutline, TiStarHalf } from 'react-icons/ti';
import { VscArrowSmallRight } from 'react-icons/vsc';
import { FiAlertCircle } from 'react-icons/fi';
import { GoKebabVertical } from 'react-icons/go';
import SliderSlick, { Settings } from 'react-slick';
import { RiArrowDropRightLine, RiSettings3Fill } from 'react-icons/ri';

import { Container, Modal, NoData } from './styles';
import FloatProfile from '~/components/FloatProfile';

import novidades01 from '~/assets/banners/novidades-01.png';
import soccerShoe from '~/assets/defaults/soccer-shoe.png';
import api from '~/services/api';

interface IBanner {
  id: string;
  name: string;
  photo_url: string;
}

interface INew {
  id: string;
  title: string;
  description: string;
  name: string;
  path: string;
  photo_url: string;
}

interface IModel {
  id: string;
  name: string;
  brand: string;
  evaluation: number | string;
  photo_url: string;
}

const News: React.FC = () => {
  const location = useLocation();
  const [isModels, setIsModels] = useState(false);
  const [show, setShow] = useState(false);
  const [banners, setBanners] = useState<IBanner[]>([]);
  const [news, setNews] = useState<INew[]>([]);
  const [models, setModels] = useState<IModel[]>([]);
  const [dataNew, setDataNew] = useState({} as INew);

  const settings: Settings = useMemo(
    () => ({
      infinite: true,
      slidesToShow: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      arrows: false,
      touchMove: true,
      dots: true,
    }),
    []
  );

  useEffect(() => {
    api.get<IBanner[]>('banners').then((response) => {
      setBanners(response.data);
    });
  }, []);

  useEffect(() => {
    if (isModels) {
      api.get<IModel[]>('models').then((response) => {
        const data = response.data.map((model) => ({
          ...model,
          evaluation: parseFloat(model.evaluation as string),
        }));
        setModels(data);
      });
    } else {
      api.get<INew[]>('news').then((response) => {
        setNews(response.data);
      });
    }
  }, [isModels]);

  useEffect(() => {
    setIsModels(location.pathname.includes('/modelos'));
  }, [location.pathname]);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const handleShowNew = useCallback((newData) => {
    setDataNew(newData);
    setShow(true);
  }, []);

  return (
    <Container className="py-5">
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-6 order-1 order-lg-0 mb-4 mb-lg-5 d-flex align-items-center">
            <h2 className="fw-semibold mb-0">Novidades</h2>
            <Link
              to={`${process.env.PUBLIC_URL}/novidades/gerenciar/banners`}
              className="btn btn-manage rounded-pill px-lg-3 fw-medium d-flex align-items-center justify-content-center mb-0 ms-4"
            >
              <RiSettings3Fill size={19} color="#EAEAEA" className="me-2" />
              <span className="fw-medium">Gerenciar</span>
            </Link>
          </div>
          <div className="col-lg-6 d-none d-lg-flex justify-content-end pe-lg-5">
            <FloatProfile />
          </div>
          <div className="col-12 pe-lg-5 my-4 banner">
            <SliderSlick {...settings}>
              {banners.map((banner) => (
                <img
                  key={banner.id}
                  src={banner.photo_url}
                  alt={banner.name}
                  className="w-100 img-banner"
                />
              ))}
            </SliderSlick>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 pe-lg-5">
            <div className="d-flex justify-content-between align-items-center pb-3 options">
              <div>
                <NavLink
                  to={`${process.env.PUBLIC_URL}/novidades`}
                  className="border-0 bg-transparent pb-1 px-3 fw-medium"
                  exact
                  activeClassName="active"
                >
                  Novidades
                </NavLink>
                <NavLink
                  to={`${process.env.PUBLIC_URL}/novidades/modelos`}
                  className="border-0 bg-transparent pb-1 px-3 fw-medium"
                  exact
                  activeClassName="active"
                >
                  Modelos
                </NavLink>
              </div>
            </div>
            {!isModels ? (
              <div className="row align-items-start mt-3 news">
                {news.length > 0 ? (
                  <>
                    {news.map((newData) => (
                      <button
                        key={newData.id}
                        type="button"
                        className="col-lg-4 mb-5 border-0 bg-transparent"
                        onClick={() => handleShowNew(newData)}
                      >
                        <img
                          src={newData.photo_url}
                          alt=""
                          className="w-100 mb-2"
                        />
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="fw-semibold mb-0">{newData.title}</p>
                          <RiArrowDropRightLine size={24} color="#000000" />
                        </div>
                      </button>
                    ))}
                  </>
                ) : (
                  <NoData className="col-12 d-flex justify-content-center pt-5">
                    <p className="h3">Nenhuma novidade cadastrada</p>
                  </NoData>
                )}
              </div>
            ) : (
              <div className="row mt-3 models">
                {models.length > 0 ? (
                  <>
                    {models.map((model) => (
                      <div className="col-lg-4 col-xl-3 mb-4 border-0 bg-transparent">
                        <div className="box-model">
                          <img
                            src={model.photo_url}
                            alt=""
                            className="w-100 mb-2"
                          />
                          <div className="p-4">
                            <p className="mb-0 small mb-1">Nome:</p>
                            <p className="fw-semibold small">
                              <span>{model.name}</span>
                            </p>
                            <p className="small">
                              Marca:{' '}
                              <span className="fw-medium">{model.brand}</span>
                            </p>
                            <p className="mb-0 small">Avaliação:</p>
                            <div className="d-flex">
                              {model.evaluation < 1 && (
                                <TiStarHalf size={20} color="#FFD90F" />
                              )}
                              {(model.evaluation === 1 ||
                                model.evaluation > 1) && (
                                <TiStar size={20} color="#FFD90F" />
                              )}
                              {model.evaluation > 1 && model.evaluation < 2 && (
                                <TiStarHalf size={20} color="#FFD90F" />
                              )}
                              {(model.evaluation === 2 ||
                                model.evaluation > 2) && (
                                <TiStar size={20} color="#FFD90F" />
                              )}
                              {model.evaluation > 2 && model.evaluation < 3 && (
                                <TiStarHalf size={20} color="#FFD90F" />
                              )}
                              {(model.evaluation === 3 ||
                                model.evaluation > 3) && (
                                <TiStar size={20} color="#FFD90F" />
                              )}
                              {model.evaluation > 3 && model.evaluation < 4 && (
                                <TiStarHalf size={20} color="#FFD90F" />
                              )}
                              {(model.evaluation === 4 ||
                                model.evaluation > 4) && (
                                <TiStar size={20} color="#FFD90F" />
                              )}
                              {model.evaluation > 4 && model.evaluation < 5 && (
                                <TiStarHalf size={20} color="#FFD90F" />
                              )}
                              {model.evaluation === 5 && (
                                <TiStar size={20} color="#FFD90F" />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <NoData className="col-12 d-flex justify-content-center pt-5">
                    <p className="h3">Nenhum modelo cadastrado</p>
                  </NoData>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header className="border-0" closeButton />
        <Modal.Body>
          {Object.keys(dataNew).length > 0 && (
            <div className="row">
              <div className="col-lg-4">
                <img
                  src={dataNew.photo_url}
                  alt={dataNew.title}
                  className="w-100 mb-2"
                />
              </div>
              <div className="col-lg-8 px-2 py-4 p-lg-5">
                <h3 className="h2 mb-4 fw-semibold">{dataNew.title}</h3>
                <p className="mb-5">{dataNew.description}</p>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default News;
