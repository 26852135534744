import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '~/pages/SignIn';
import ForgotPassword from '~/pages/ForgotPassword';
import ResetPassword from '~/pages/ResetPassword';

import Dashboard from '~/pages/Dashboard';
import Members from '~/pages/Members';
import Member from '~/pages/Member';
import MyPerformance from '~/pages/MyPerformance';
import Subscriptions from '~/pages/Subscriptions';
import News from '~/pages/News';
import NewsManage from '~/pages/NewsManage';
import Profile from '~/pages/Profile';

const routes: React.FC = () => {
  return (
    <Switch>
      <Route path={`${process.env.PUBLIC_URL}/`} exact component={SignIn} />
      <Route
        path={`${process.env.PUBLIC_URL}/esqueci-a-senha`}
        exact
        component={ForgotPassword}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/resetar-senha/:code`}
        exact
        component={ResetPassword}
      />

      <Route
        path={`${process.env.PUBLIC_URL}/dashboard`}
        exact
        isPrivate
        component={Dashboard}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/membros`}
        exact
        isPrivate
        component={Members}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/membros/:user_id`}
        exact
        isPrivate
        component={Member}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/minha-performance`}
        exact
        isPrivate
        component={MyPerformance}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/assinaturas`}
        exact
        isPrivate
        component={Subscriptions}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/novidades`}
        exact
        isPrivate
        component={News}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/novidades/modelos`}
        exact
        isPrivate
        component={News}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/novidades/gerenciar/banners`}
        exact
        isPrivate
        component={NewsManage}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/novidades/gerenciar/novidades`}
        exact
        isPrivate
        component={NewsManage}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/novidades/gerenciar/modelos`}
        exact
        isPrivate
        component={NewsManage}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/perfil`}
        exact
        isPrivate
        component={Profile}
      />
    </Switch>
  );
};

export default routes;
