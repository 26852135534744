import React, { createContext, useCallback, useState, useContext } from 'react';
import Swal from 'sweetalert2';

import api from '../services/api';

export interface IUserResponse {
  id: string;
  name: string;
  birthdate: string;
  celphone: string;
  email: string;
  avatar?: {
    avatar_id: string;
    avatar_url: string;
  };
}

export interface IUser {
  id: string;
  name: string;
  fullName: string;
  birthdate: string;
  celphone: string;
  email: string;
  avatar?: {
    avatar_id: string;
    avatar_url: string;
  };
}

interface AuthState {
  token: string;
  user: IUser;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: IUser;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: IUser): void;
}

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData
);

export const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@Kanarin:token');
    const user = localStorage.getItem('@Kanarin:user');

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password }: SignInCredentials) => {
    try {
      const response = await api.post<AuthState>(`admins/sessions`, {
        email,
        password,
      });

      const { token, user } = response.data;

      localStorage.setItem('@Kanarin:token', token);
      localStorage.setItem('@Kanarin:user', JSON.stringify(user));

      api.defaults.headers.authorization = `Bearer ${token}`;

      const nameParts = user.name.split(' ');

      setData({
        token,
        user: {
          ...user,
          name: `${nameParts[0]} ${nameParts[1]}`,
          fullName: user.name,
        },
      });
    } catch (error) {
      console.log(error);
      Swal.fire(
        'Opss...',
        'Ocorreu um erro ao logar, verifique seus dados.',
        'error'
      );
    }
  }, []);

  const signOut = useCallback(async () => {
    localStorage.removeItem('@Kanarin:token');
    localStorage.removeItem('@Kanarin:user');

    setData({} as AuthState);
  }, []);

  const updateUser = useCallback(
    (user: IUser) => {
      localStorage.setItem('@Kanarin:user', JSON.stringify(user));

      setData({
        token: data.token,
        user,
      });
    },
    [setData, data.token]
  );

  return (
    <AuthContext.Provider
      value={{ user: data.user, signIn, signOut, updateUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
