import React, { useCallback, useRef, useState } from 'react';
import { RiArrowLeftSLine } from 'react-icons/ri';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import Lottie from 'react-lottie';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Link } from 'react-router-dom';

import getValidationsErrors from '~/utils/getValidationsErrors';

import { Container, Background } from './styles';
import Input from '~/components/Input';

import sendMail from '~/assets/animations/send_mail.json';

interface IFormData {
  email: string;
}

const ForgotPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [emailSent, setEmailSent] = useState(false);

  const handleSubmit = useCallback(async (data: IFormData) => {
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        email: Yup.string()
          .email('Informe um e-mail válido')
          .required('O e-mail é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      // await api.post('users/sessions/forgot-password', {
      //   email: data.email.toLowerCase(),
      // });

      setEmailSent(true);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationsErrors(error);
        formRef.current?.setErrors(errors);
      } else {
        Swal.fire(
          'Opss...',
          'Ocorreu um erro ao logar, verifique seus dados.',
          'error'
        );
      }
    }
  }, []);

  return (
    <Container className="container-fluid d-flex flex-column justify-content-center py-4 py-lg-0">
      <div className="row h-100">
        <Background className="d-none d-lg-block col-lg-6 ms-auto" />
        <div className="col-12 h-100">
          <div className="container h-100">
            <div className="row h-100 justify-content-end">
              {!emailSent ? (
                <>
                  <Form
                    ref={formRef}
                    onSubmit={handleSubmit}
                    className="col-lg-6 align-self-center px-4 px-lg-3"
                  >
                    <div className="row justify-content-center">
                      <div className="col-lg-9 me-lg-5">
                        <Link to="/" className="d-block mb-5">
                          <RiArrowLeftSLine size={44} color="#B8B9BA" />
                        </Link>
                        <h1 className="fw-semibold h2 mb-4">
                          Esqueceu a senha?
                        </h1>
                        <h2 className="fw-normal mb-5 h5">
                          Tudo bem, insira seu e-mail para restaurar
                        </h2>
                        <div className="row">
                          <div className="col-lg-10">
                            <label className="d-block w-100 py-3">
                              <span className="d-block mb-2">E-mail</span>
                              <Input
                                type="email"
                                name="email"
                                placeholder="example@email.com"
                                className="input"
                              />
                            </label>
                            <div className="pt-2 w-100">
                              <button
                                type="submit"
                                className="btn btn-submit d-block w-100 mt-5 fw-semibold mb-3"
                              >
                                Continuar
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                  <div className="col-lg-6 d-none d-lg-block" />
                </>
              ) : (
                <>
                  <div className="col-lg-6 align-self-center px-4 px-lg-3">
                    <div className="row justify-content-center">
                      <div className="col-lg-9 me-lg-5">
                        <div className="mb-4 pb-5">
                          <h2 className="fw-semibold mb-1 text-center">
                            Email enviado com sucesso
                          </h2>
                          <p className="text-gray text-center h5 fw-normal">
                            Verifique sua caixa de entrada, spam ou
                            lixo&nbsp;eletrônico
                          </p>
                        </div>
                        <Lottie
                          options={{
                            animationData: sendMail,
                            autoplay: true,
                            loop: false,
                            rendererSettings: {
                              preserveAspectRatio: 'xMidYMid slice',
                            },
                          }}
                          height={186}
                          width={186}
                        />
                        <Link
                          to={`${process.env.PUBLIC_URL}/`}
                          className="btn btn-submit d-flex align-items-center justify-content-center w-100 mt-5 fw-semibold mb-3"
                        >
                          Voltar para Login
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 d-none d-lg-block" />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ForgotPassword;
