import { darken, lighten } from 'polished';
import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

interface IButtonEvaluation {
  value: number;
  current_value: number;
}

export const Container = styled.div`
  h2 {
    color: #202020;
  }

  .btn-manage {
    border: 1px solid #202020 !important;
    background-color: #202020 !important;
    color: #ffffff !important;

    :hover {
      border-color: ${lighten(0.1, '#202020')} !important;
      background-color: ${lighten(0.1, '#202020')} !important;
    }
  }

  .my-games {
    border-bottom: 4px solid #efefef;

    > div {
      margin-bottom: -20px;

      a {
        display: inline-block;
        transition-duration: 0.3s;
        color: #a4a4a4;
        border-bottom: 4px solid transparent !important;
      }

      a:hover,
      a.active {
        color: #100f0f;
        border-color: #0d0d0d !important;
      }
    }

    .btn-new {
      border-color: #00ab64 !important;
      background-color: #00ab64 !important;
      color: #ffffff !important;

      :hover {
        border-color: ${darken(0.05, '#00AB64')} !important;
        background-color: ${darken(0.05, '#00AB64')} !important;
      }
    }
  }

  .input {
    background: #f0f0f0;
    border: 1px solid #e4e4e4 !important;
    border-radius: 8px;

    input {
      color: #202020;

      ::placeholder {
        color: #a4a4a4;
      }
    }
  }

  .banners {
    .btn-new {
      background: #dbfaed !important;
      border: 1px solid #97f8cf !important;
      border-radius: 10px !important;
      color: #07653e;
    }

    form.col-12 {
      .box {
        background: #ffffff;
        border: 1px solid #d9d9d9;
        border-radius: 10px;

        .input-image {
          > div > div {
            label {
              border: none;
            }
          }

          label:not(.first-label) {
            background: #f0f0f0;
            height: 150px;

            hr {
              border: 1px solid #cbcbcb;
              opacity: 1;
              width: 250px;
            }

            span {
              background: #707070;
              color: #ffffff;
              width: 150px;
            }
          }
        }

        .saved {
          .btn-remove-photo {
            display: none;
          }
        }

        .btn-submit {
          color: #202020;

          div {
            width: 30px;
            height: 30px;
            background: #202020;
            border-radius: 50%;
          }
        }

        .btn-edit {
          div {
            width: 30px;
            height: 30px;
            background: #b6b7b6;
            border-radius: 50%;
          }
        }

        .btn-delete {
          color: #e51810;

          div {
            width: 30px;
            height: 30px;
            background: #e51810;
            border-radius: 50%;
          }
        }
      }

      + form {
        margin-top: 16px;
      }
    }
  }

  .news {
    .btn-add-news {
      width: 100%;
      height: 198.97px;
      background: #f0f0f0;
      border: 1px solid #d6d7cf;
      border-radius: 16px;

      p {
        width: 99px;
        color: #707070;
      }
    }

    img {
      mix-blend-mode: normal;
      border-radius: 16px;
    }

    p {
      color: #202020;
    }
  }

  .models {
    .btn-add-models {
      width: 100%;
      height: 200px;
      background: #f0f0f0;
      border: 1px solid #d6d7cf;
      border-radius: 16px;

      p {
        width: 110px;
        color: #707070;
      }
    }

    .box-model {
      background: #ffffff;
      border: 1px solid #d7cfcf;
      box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.1);
      border-radius: 17px;

      img {
        border-radius: 17px 17px 0px 0px;
      }

      p span {
        color: #202020;
      }
    }
  }
`;

export const Score = styled.div`
  background: rgba(66, 169, 76, 0.1);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;

  p {
    color: #42a959;
    font-weight: 600;
    margin: 0;
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    border: none;
    border-radius: 10px;
    padding: 1.5rem;

    .close {
      font-size: 24px;
      border: none;
      position: absolute;
      top: 20px;
      right: 40px;
      font-size: 30px;
      background: none;

      .sr-only {
        display: none;
      }
    }

    img {
      border-radius: 10px;
    }

    h3.h2 {
      color: #202020;
    }

    .input-image {
      > div {
        height: 100%;

        > div {
          width: 100%;
          height: 100%;
          border: none;

          label {
            border: none;
          }
        }

        label:not(.first-label) {
          background: #f0f0f0;
          height: 100%;

          hr {
            background-color: #cbcbcb;
            opacity: 1;
            width: 250px;
            border: none;
            height: 1px;
          }

          span {
            background: #707070;
            color: #ffffff;
            width: 150px;
          }
        }

        .btn-remove-photo {
          bottom: -10px;
        }
      }
    }

    .saved {
      > div > div {
        width: 100%;
        height: 100%;
      }

      .btn-remove-photo {
        display: none;
      }
    }

    .input,
    .textarea {
      border: none;
    }

    .input {
      input {
        color: #202020;
        font-weight: 600;
        font-size: 32px;

        ::placeholder {
          color: #202020;
          font-weight: 600;
        }
      }
    }

    .textarea {
      border: none;

      textarea {
        color: #707070;

        ::placeholder {
          color: #707070;
        }
      }
    }

    .btn-submit {
      color: #202020;

      div {
        width: 30px;
        height: 30px;
        background: #202020;
        border-radius: 50%;
      }
    }

    .btn-edit {
      div {
        width: 30px;
        height: 30px;
        background: #b6b7b6;
        border-radius: 50%;
      }
    }

    .btn-delete {
      color: #e51810;

      div {
        width: 30px;
        height: 30px;
        background: #e51810;
        border-radius: 50%;
      }
    }

    .news {
      .input {
        background: #f0f0f0;
        border: 1px solid #e4e4e4 !important;
        border-radius: 8px;

        input {
          color: #202020;
          font-size: 16px;

          ::placeholder {
            color: #a4a4a4;
          }
        }
      }
    }
  }
`;

export const ButtonEvaluation = styled.button<IButtonEvaluation>`
  width: 20px;
  overflow: hidden;

  svg {
    transition-duration: 0.1s;
    fill: ${(props) =>
      props.current_value >= props.value ? '#FFD90F' : '#E8E7E2'};
  }

  :nth-child(even) {
    transform: rotateY(180deg);
  }
`;
