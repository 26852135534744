import styled from 'styled-components';

export const Container = styled.div`
  > div {
    > div:nth-child(1) {
      background: #ffffff;
      box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05);
      position: relative;

      button {
        width: 24px;
        height: 24px;

        span {
          background: #000000;
          width: 24px;
          height: 3px;
          border-radius: 2px;
          display: block;
          transition-duration: 0.3s;
          opacity: 1;
        }

        span:nth-child(2) {
          width: 16px;
          margin: 4px 0;
        }
      }

      button.opened {
        span {
          :nth-child(1) {
            transform: rotateZ(45deg);
          }
          :nth-child(2) {
            opacity: 0;
            margin: -3px 0;
          }
          :nth-child(3) {
            transform: rotateZ(-45deg);
          }
        }
      }
    }

    > div:nth-child(2) {
      width: 100%;
      min-height: 100vh;
      background: #ffffff;
      position: absolute;
      z-index: -1;
      left: -100%;
      transition-duration: 0.3s;

      .box {
        background: #f9f9f9;
        border: 1px solid #d3d3d3;
        border-radius: 9px;
      }

      a,
      button {
        transition-duration: 0.3s;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: transparent;
      }

      a {
        -webkit-text-fill-color: #707070;

        svg {
          transition-duration: 0.3s;
          fill: #707070;
        }
      }

      a:hover,
      a.active {
        -webkit-text-stroke-color: #00ab64;
        -webkit-text-fill-color: #00ab64;

        svg {
          fill: #00ab64;
        }
      }

      button {
        -webkit-text-fill-color: #e14640;
        :hover {
          -webkit-text-stroke-color: #e14640;
        }
      }
    }

    div.opened {
      left: 0;
    }
  }

  @media screen and (min-width: 992px) {
    > div {
      width: 20%;
      > div:nth-child(2) {
        width: 100%;
        height: 100vh;
        position: unset;
        background: transparent;
      }
    }
  }
`;
