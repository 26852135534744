import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { TiStar, TiStarHalf } from 'react-icons/ti';
import {
  RiArrowDropRightLine,
  RiArrowLeftSLine,
  RiEdit2Fill,
} from 'react-icons/ri';
import { HiOutlineTrash } from 'react-icons/hi';
import { BiSave } from 'react-icons/bi';
import { MdImage } from 'react-icons/md';
import { BsPlus } from 'react-icons/bs';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import getValidationsErrors from '~/utils/getValidationsErrors';
import Toast from '~/utils/toast';

import { ButtonEvaluation, Container, Modal } from './styles';
import FloatProfile from '~/components/FloatProfile';
import Input from '~/components/Input';
import InputPhoto from '~/components/InputPhoto';
import Textarea from '~/components/Textarea';

import soccerShoe from '~/assets/defaults/soccer-shoe.png';
import api from '~/services/api';
import { FormHandles } from '@unform/core';

interface IBannerResponse {
  id: string;
  photo_url: string;
  order: number;
}

interface IBannerFormData {
  order: string;
}

interface INewFormData {
  title: string;
  description: string;
}

interface IModelFormData {
  name: string;
  brand: string;
}

interface IError {
  image: string;
  order: string;
}

interface IErrors {
  [key: string]: IError;
}

interface IBanner {
  id: string;
  width: number;
  height: number;
  x: number;
  y: number;
  url?: string;
  file?: File;
  order: number;
  saved: boolean;
  new: boolean;
  edit: boolean;
}

interface INew {
  id: string;
  title: string;
  description: string;
  name: string;
  path: string;
  photo_url: string;
}

interface IModel {
  id: string;
  name: string;
  brand: string;
  evaluation: number | string;
  photo_url: string;
}

interface IImageCropConfig {
  width: number;
  height: number;
  x: number;
  y: number;
}

const NewsManage: React.FC = () => {
  const location = useLocation();
  const newFormRef = useRef<FormHandles>(null);
  const updateNewFormRef = useRef<FormHandles>(null);
  const modelFormRef = useRef<FormHandles>(null);
  const updateModelFormRef = useRef<FormHandles>(null);
  const [banners, setBanners] = useState<IBanner[]>([]);
  const [isBanners, setIsBanners] = useState(true);
  const [isNews, setIsNews] = useState(false);
  const [isModels, setIsModels] = useState(false);
  const [show, setShow] = useState(false);
  const [errorsByBanners, setErrorsByBanners] = useState({} as IErrors);
  const [showAddNews, setShowAddNews] = useState(false);
  const [showEditNews, setShowEditNews] = useState(false);
  const [editNews, setEditNews] = useState(false);
  const [showAddModels, setShowAddModels] = useState(false);
  const [showEditModels, setShowEditModels] = useState(false);
  const [evaluation, setEvaluation] = useState(0);
  const [evaluationAux, setEvaluationAux] = useState(0);
  const [imageCropConfig, setImageCropConfig] = useState<IImageCropConfig>({
    width: 80,
    height: 80,
    x: 0,
    y: 0,
  });
  const [newImage, setNewImage] = useState<File | undefined>(undefined);
  const [news, setNews] = useState<INew[]>([]);
  const [newSelected, setNewSelected] = useState({} as INew);
  const [modelImage, setModelImage] = useState<File | undefined>(undefined);
  const [models, setModels] = useState<IModel[]>([]);
  const [modelSelected, setModelSelected] = useState({} as IModel);

  useEffect(() => {
    setIsBanners(location.pathname.includes('/gerenciar/banners'));
    setIsNews(location.pathname.includes('/gerenciar/novidades'));
    setIsModels(location.pathname.includes('/gerenciar/modelos'));
  }, [location.pathname]);

  useEffect(() => {
    if (isBanners) {
      api.get<IBannerResponse[]>('banners').then((response) => {
        const data = response.data.map<IBanner>((banner) => ({
          id: banner.id,
          width: 150,
          height: 40.33,
          x: 0,
          y: 0,
          url: banner.photo_url,
          file: undefined,
          order: banner.order,
          saved: true,
          new: false,
          edit: false,
        }));

        setBanners(data);
      });
    }

    if (isNews) {
      api.get<INew[]>('news').then((response) => {
        setNews(response.data);
      });
    }

    if (isModels) {
      api.get<IModel[]>('models').then((response) => {
        setModels(response.data);
      });
    }
  }, [isBanners, isModels, isNews]);

  const handleClose = useCallback(() => {
    setShow(false);
    setShowAddNews(false);
    setShowEditNews(false);
    setShowAddModels(false);
    setShowEditModels(false);
    setEditNews(false);
    setEvaluation(0);
    setEvaluationAux(0);
    setNewSelected({} as INew);
    setNewImage(undefined);
    setModelSelected({} as IModel);
    setModelImage(undefined);
  }, []);

  const handleClickAddBanner = useCallback(() => {
    const newBanner: IBanner = {
      id: (banners.length + 1).toFixed(),
      width: 150,
      height: 40.33,
      x: 0,
      y: 0,
      order: banners.length + 1,
      new: true,
      edit: false,
      saved: false,
    };

    setBanners((state) => [newBanner, ...state]);
  }, [banners.length]);

  const handleChangeBanner = useCallback(
    (file, banner_id, originalFile, dimensions) => {
      const newBanners = banners.slice();
      const bannerIndex = newBanners.findIndex(
        (banner) => banner.id === banner_id
      );

      if (bannerIndex >= 0) {
        newBanners[bannerIndex].url = URL.createObjectURL(originalFile);
        newBanners[bannerIndex].width = dimensions.width;
        newBanners[bannerIndex].height = dimensions.height;
        newBanners[bannerIndex].x = dimensions.x;
        newBanners[bannerIndex].y = dimensions.y;
        newBanners[bannerIndex].file = file;
      }
      setBanners(newBanners);
    },
    [banners]
  );

  const handleRemoveBanner = useCallback(
    (banner_id) => {
      const newBanners = banners.slice();
      const bannerIndex = newBanners.findIndex(
        (banner) => banner.id === banner_id
      );

      if (bannerIndex >= 0) {
        newBanners[bannerIndex].file = undefined;
        newBanners[bannerIndex].url = undefined;
      }

      setBanners(newBanners);
    },
    [banners]
  );

  const handleClickEditBanner = useCallback(
    (banner_id) => {
      const newBanners = banners.slice();
      const bannerIndex = newBanners.findIndex(
        (banner) => banner.id === banner_id
      );

      if (bannerIndex >= 0) {
        newBanners[bannerIndex].edit = true;
        newBanners[bannerIndex].saved = false;
      }

      setTimeout(() => {
        setBanners(newBanners);
      }, 100);
    },
    [banners]
  );

  const handleClickDeleteBanner = useCallback(
    (banner) => {
      Swal.fire({
        title: 'Deseja deletar esse banner?',
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#e51810',
        cancelButtonColor: '#202020',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            if (banner.id.split('-').length > 1) {
              await api.delete(`banners/${banner.id}`);
            }
            const newBanners = banners.filter(
              (bannerData) => bannerData.id !== banner.id
            );
            setBanners(newBanners);

            Toast.fire({
              icon: 'success',
              title: 'Banner deletado!',
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [banners]
  );

  const handleSubmitBanner = useCallback(
    async (data: IBannerFormData, banner_id: string) => {
      const newErrorsByBanners = JSON.parse(
        JSON.stringify(errorsByBanners)
      ) as IErrors;
      if (newErrorsByBanners[banner_id]) {
        newErrorsByBanners[banner_id] = {
          image: '',
          order: '',
        };
      }
      try {
        const schema = Yup.object().shape({
          order: Yup.string().required('A posição é obrigatória'),
          image: Yup.string().when('$banner', {
            is: (banner: boolean) => !banner,
            then: Yup.string().required('A imagem é obrigatória'),
            otherwise: Yup.string(),
          }),
        });

        const newBanners = banners.slice();
        const bannerIndex = newBanners.findIndex(
          (banner) => banner.id === banner_id
        );

        await schema.validate(data, {
          abortEarly: false,
          context: {
            banner: newBanners[bannerIndex] && newBanners[bannerIndex].url,
          },
        });

        const { order } = data;

        if (bannerIndex >= 0) {
          const formData = new FormData();
          if (newBanners[bannerIndex].file) {
            formData.append('file', newBanners[bannerIndex].file as File);
          }
          formData.append('order', order);

          if (newBanners[bannerIndex].new) {
            const response = await api.post('banners', formData);
            newBanners[bannerIndex].id = response.data.id;
          } else if (newBanners[bannerIndex].edit) {
            await api.put(`banners/${banner_id}`, formData);
          }

          newBanners[bannerIndex].order = parseInt(order, 10);
          newBanners[bannerIndex].url = newBanners[bannerIndex].file
            ? URL.createObjectURL(newBanners[bannerIndex].file as File)
            : newBanners[bannerIndex].url;
          newBanners[bannerIndex].width = 150;
          newBanners[bannerIndex].height = 40.33;
          newBanners[bannerIndex].x = 0;
          newBanners[bannerIndex].y = 0;
          newBanners[bannerIndex].saved = true;
          newBanners[bannerIndex].new = false;
          newBanners[bannerIndex].edit = false;
        }

        newBanners.sort((a, b) => {
          return a.order - b.order;
        });

        setBanners(newBanners);
      } catch (error) {
        console.log(error);
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationsErrors(error);

          newErrorsByBanners[banner_id] = {
            image: errors.image || '',
            order: errors.order || '',
          };
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro ao logar, verifique seus dados.',
            'error'
          );
        }
      } finally {
        setErrorsByBanners(newErrorsByBanners);
      }
    },
    [errorsByBanners, banners]
  );

  const hadleClickAddNews = useCallback(() => {
    setImageCropConfig({
      width: 87.88,
      height: 150,
      x: 0,
      y: 0,
    });
    setShowAddNews(true);
  }, []);

  const handleShowNew = useCallback((newData) => {
    setNewSelected(newData);
    setImageCropConfig({
      width: 87.88,
      height: 150,
      x: 0,
      y: 0,
    });
    setShowEditNews(true);
  }, []);

  const handleChangeImageNews = useCallback((file, dimensions) => {
    setNewImage(file);
    if (dimensions) {
      setImageCropConfig(dimensions);
    } else {
      setImageCropConfig({
        width: 87.88,
        height: 150,
        x: 0,
        y: 0,
      });
    }
  }, []);

  const handleRemoveImageNews = useCallback(() => {
    setNewImage(undefined);
  }, []);

  const handleSubmitNews = useCallback(
    async (data: INewFormData) => {
      try {
        newFormRef.current?.setErrors({});
        const schema = Yup.object().shape({
          title: Yup.string().required('O título é obrigatório'),
          description: Yup.string().required('A descrição é obrigatória'),
          image: Yup.string().when('$photo', {
            is: (photo: boolean) => !photo,
            then: Yup.string().required('A imagem é obrigatória'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            photo: !!newImage,
          },
        });

        const { title, description } = data;

        const formData = new FormData();
        formData.append('file', newImage as File);
        formData.append('title', title);
        formData.append('description', description);

        const response = await api.post('news', formData);

        setNews((state) => [...state, response.data]);
        handleClose();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationsErrors(error);

          newFormRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro ao logar, verifique seus dados.',
            'error'
          );
        }
      }
    },
    [handleClose, newImage]
  );

  const handleClickEditNews = useCallback(() => {
    setTimeout(() => {
      setEditNews(true);
    }, 100);
  }, []);

  const handleClickDeleteNews = useCallback(() => {
    Swal.fire({
      title: 'Deseja deletar essa novidade?',
      icon: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#e51810',
      cancelButtonColor: '#202020',
      cancelButtonText: 'Não',
      reverseButtons: true,
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          if (Object.keys(newSelected).length > 0) {
            await api.delete(`news/${newSelected.id}`);
            const newNews = news.filter(
              (newData) => newData.id !== newSelected.id
            );
            setNews(newNews);
          }

          handleClose();

          Toast.fire({
            icon: 'success',
            title: 'Novidade deletada!',
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [handleClose, newSelected, news]);

  const handleSubmitUpdateNews = useCallback(
    async (data: INewFormData) => {
      try {
        const schema = Yup.object().shape({
          title: Yup.string().required('O título é obrigatório'),
          description: Yup.string().required('A descrição é obrigatória'),
          image: Yup.string().when('$photo', {
            is: (photo: boolean) => !photo,
            then: Yup.string().required('A imagem é obrigatória'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            photo: !!newSelected.photo_url,
          },
        });

        const { title, description } = data;

        const formData = new FormData();
        if (newImage) {
          formData.append('file', newImage);
        }
        formData.append('title', title);
        formData.append('description', description);

        await api.put(`news/${newSelected.id}`, formData);

        const newNews = news.slice();
        const newIndex = newNews.findIndex(
          (newData) => newData.id === newSelected.id
        );

        if (newIndex >= 0) {
          newNews[newIndex].title = title;
          newNews[newIndex].description = description;

          if (newImage) {
            newNews[newIndex].photo_url = URL.createObjectURL(newImage);
          }
        }

        setNews(newNews);
        handleClose();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationsErrors(error);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro ao logar, verifique seus dados.',
            'error'
          );
        }
      }
    },
    [handleClose, newImage, newSelected.id, newSelected.photo_url, news]
  );

  const hadleClickAddModels = useCallback(() => {
    setShowAddModels(true);
  }, []);

  const hadleClickUpdateModels = useCallback((model) => {
    setEvaluation(model.evaluation);
    setModelSelected(model);
    setImageCropConfig({
      width: 100,
      height: 81.41,
      x: 0,
      y: 0,
    });
    setShowEditModels(true);
  }, []);

  const handleChangeImageModels = useCallback((file, dimensions) => {
    setModelImage(file);
    if (dimensions) {
      setImageCropConfig(dimensions);
    } else {
      setImageCropConfig({
        width: 100,
        height: 81.41,
        x: 0,
        y: 0,
      });
    }
  }, []);

  const handleRemoveImageModels = useCallback(() => {
    setModelImage(undefined);
  }, []);

  const handleMouseEnterEvaluation = useCallback((value) => {
    setEvaluationAux(value);
  }, []);

  const handleMouseLeaveEvaluation = useCallback(() => {
    setEvaluationAux(0);
  }, []);

  const handleSelectEvaluation = useCallback((value) => {
    setEvaluation(value);
  }, []);

  const handleSubmitModels = useCallback(
    async (data: IModelFormData) => {
      try {
        updateModelFormRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório'),
          brand: Yup.string().required('A marca é obrigatória'),
          image: Yup.string().when('$photo', {
            is: (photo: boolean) => !photo,
            then: Yup.string().required('A imagem é obrigatória'),
            otherwise: Yup.string(),
          }),
          evaluation: Yup.string().when('$evaluationCheck', {
            is: (evaluationCheck: boolean) => !evaluationCheck,
            then: Yup.string().required('A avaliação é obrigatória'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            photo: !!modelImage,
            evaluationCheck: !!evaluation,
          },
        });

        const { name, brand } = data;

        const formData = new FormData();
        formData.append('file', modelImage as File);
        formData.append('name', name);
        formData.append('brand', brand);
        formData.append('evaluation', evaluation.toString());

        const response = await api.post(`models`, formData);

        setModels((state) => [...state, response.data]);
        handleClose();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationsErrors(error);

          updateModelFormRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro ao logar, verifique seus dados.',
            'error'
          );
        }
      }
    },
    [evaluation, handleClose, modelImage]
  );

  const handleSubmitUpdateModels = useCallback(
    async (data: IModelFormData) => {
      try {
        updateModelFormRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório'),
          brand: Yup.string().required('A marca é obrigatória'),
          image: Yup.string().when('$photo', {
            is: (photo: boolean) => !photo,
            then: Yup.string().required('A imagem é obrigatória'),
            otherwise: Yup.string(),
          }),
          evaluation: Yup.string().when('$evaluationCheck', {
            is: (evaluationCheck: boolean) => !evaluationCheck,
            then: Yup.string().required('A avaliação é obrigatória'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            photo: !!modelSelected.photo_url,
            evaluationCheck: !!evaluation,
          },
        });

        const { name, brand } = data;

        const formData = new FormData();
        if (modelImage) {
          formData.append('file', modelImage);
        }
        formData.append('name', name);
        formData.append('brand', brand);
        formData.append('evaluation', evaluation.toString());

        await api.put(`models/${modelSelected.id}`, formData);

        const newModel = models.slice();
        const modelIndex = newModel.findIndex(
          (modelData) => modelData.id === modelSelected.id
        );

        if (modelIndex >= 0) {
          newModel[modelIndex].name = name;
          newModel[modelIndex].brand = brand;
          newModel[modelIndex].evaluation = evaluation;

          if (modelImage) {
            newModel[modelIndex].photo_url = URL.createObjectURL(modelImage);
          }
        }

        setModels(newModel);
        handleClose();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationsErrors(error);

          updateModelFormRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro ao logar, verifique seus dados.',
            'error'
          );
        }
      }
    },
    [
      evaluation,
      handleClose,
      modelImage,
      modelSelected.id,
      modelSelected.photo_url,
      models,
    ]
  );

  const handleClickDeleteModels = useCallback(() => {
    Swal.fire({
      title: 'Deseja deletar esse modelo?',
      icon: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#e51810',
      cancelButtonColor: '#202020',
      cancelButtonText: 'Não',
      reverseButtons: true,
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          if (Object.keys(modelSelected).length > 0) {
            await api.delete(`models/${modelSelected.id}`);
            const newModels = models.filter(
              (modelData) => modelData.id !== modelSelected.id
            );
            setModels(newModels);
          }

          handleClose();

          Toast.fire({
            icon: 'success',
            title: 'Modelo deletado!',
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [handleClose, modelSelected, models]);

  return (
    <Container className="py-5">
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-6 order-1 order-lg-0 mb-4 mb-lg-5 d-flex align-items-center">
            <Link to={`${process.env.PUBLIC_URL}/novidades`} className="btn">
              <RiArrowLeftSLine size={44} color="#B8B9BA" className="me-2" />
            </Link>
            <h2 className="fw-semibold mb-0">Gerenciar Novidades</h2>
          </div>
          <div className="col-lg-6 d-none d-lg-flex justify-content-end pe-lg-5">
            <FloatProfile />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 pe-lg-5">
            <div className="d-flex justify-content-between align-items-center pb-3 my-games mb-5">
              <div>
                <NavLink
                  to={`${process.env.PUBLIC_URL}/novidades/gerenciar/banners`}
                  className="border-0 bg-transparent pb-1 px-3 fw-medium"
                  exact
                  activeClassName="active"
                >
                  Banners
                </NavLink>
                <NavLink
                  to={`${process.env.PUBLIC_URL}/novidades/gerenciar/novidades`}
                  className="border-0 bg-transparent pb-1 px-3 fw-medium"
                  exact
                  activeClassName="active"
                >
                  Novidades
                </NavLink>
                <NavLink
                  to={`${process.env.PUBLIC_URL}/novidades/gerenciar/modelos`}
                  className="border-0 bg-transparent pb-1 px-3 fw-medium"
                  exact
                  activeClassName="active"
                >
                  Modelos
                </NavLink>
              </div>
            </div>
            {isBanners && (
              <div className="row banners">
                <div className="col-12 mb-4">
                  <button
                    type="button"
                    className="w-100 d-flex align-items-center justify-content-center btn-new py-2"
                    onClick={handleClickAddBanner}
                  >
                    <BsPlus size={24} color="#07653E" />
                    <span className="fw-medium mb-0 ms-3">Novo Banner</span>
                  </button>
                </div>
                {banners.map((banner) => (
                  <Form
                    key={banner.id}
                    onSubmit={(data: IBannerFormData) =>
                      handleSubmitBanner(data, banner.id)
                    }
                    initialData={banner}
                    className="col-12"
                  >
                    <div className="box p-4">
                      <div className="row">
                        <div
                          className={`col-lg-8 mb-5 input-image position-relative ${
                            banner.saved ? 'saved' : ''
                          }`}
                        >
                          <label className="first-label">
                            <span className="fw-medium d-block mb-1">
                              Imagem:
                            </span>
                          </label>
                          <InputPhoto
                            name={`banner_${banner.id}`}
                            value={banner.url}
                            cropImage={!banner.saved}
                            aspect={150 / 40.33}
                            cropOptions={{
                              unit: 'px',
                              width: banner.width,
                              height: banner.height,
                              x: banner.x,
                              y: banner.y,
                            }}
                            onChange={(value, originalFile, dimensions) =>
                              handleChangeBanner(
                                value,
                                banner.id,
                                originalFile,
                                dimensions
                              )
                            }
                            onRemove={() => handleRemoveBanner(banner.id)}
                          >
                            <div className="d-flex flex-column align-items-center justify-content-center">
                              <MdImage size={24} color="#C8C8C8" />
                              <p className="mb-0 fw-medium text-center small">
                                Arraste aqui sua imagem para <br />
                                iniciar o upload
                              </p>
                              <hr className="my-2" />
                              <span className="fw-medium small rounded-pill d-inline-block py-1">
                                Subir imagem
                              </span>
                            </div>
                          </InputPhoto>
                        </div>
                        <div className="col-lg-4 d-flex flex-column justify-content-between">
                          <div>
                            <label className="fw-medium d-block mb-1">
                              <span>Posição</span>
                            </label>
                            <Input
                              type="number"
                              name="order"
                              className="input"
                              disabled={banner.saved}
                              errorData={
                                errorsByBanners[banner.id]
                                  ? errorsByBanners[banner.id].order
                                  : ''
                              }
                            />
                          </div>
                          <div className="d-flex justify-content-end">
                            {!banner.saved ? (
                              <button
                                type="submit"
                                className="btn-submit border-0 bg-transparent d-flex align-items-center justify-content-center"
                              >
                                <div className="me-2 d-flex align-items-center justify-content-center">
                                  <BiSave size={18} color="#fff" />
                                </div>
                                Salvar
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn-edit border-0 bg-transparent d-flex align-items-center justify-content-center"
                                onMouseUp={() =>
                                  handleClickEditBanner(banner.id)
                                }
                              >
                                <div className="me-2">
                                  <RiEdit2Fill size={18} color="#fff" />
                                </div>
                                <span className="fw-medium">Editar</span>
                              </button>
                            )}
                            <button
                              type="button"
                              className="btn-delete border-0 bg-transparent d-flex align-items-center justify-content-center ms-3"
                              onClick={() => handleClickDeleteBanner(banner)}
                            >
                              <div className="me-2 d-flex align-items-center justify-content-center">
                                <HiOutlineTrash size={18} color="#fff" />
                              </div>
                              <span className="fw-medium">Excluir</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                ))}
              </div>
            )}
            {isNews && (
              <div className="row align-items-start mt-3 news">
                <button
                  type="button"
                  className="col-lg-4 mb-5 border-0 bg-transparent"
                  onClick={hadleClickAddNews}
                >
                  <div className="btn-add-news d-flex flex-column align-items-center justify-content-center">
                    <RiEdit2Fill size={32} color="#C8C8C8" />
                    <p className="fw-medium mb-0 small mt-4">
                      Adicionar Novidade
                    </p>
                  </div>
                </button>
                {news.map((newData) => (
                  <button
                    key={newData.id}
                    type="button"
                    className="col-lg-4 mb-5 border-0 bg-transparent"
                    onClick={() => handleShowNew(newData)}
                  >
                    <img
                      src={newData.photo_url}
                      alt={newData.path}
                      className="w-100 mb-2"
                    />
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="fw-semibold mb-0">{newData.title}</p>
                      <RiArrowDropRightLine size={24} color="#000000" />
                    </div>
                  </button>
                ))}
              </div>
            )}
            {isModels && (
              <div className="row mt-3 models align-items-start">
                <button
                  type="button"
                  className="col-lg-4 col-xl-3 mb-4 border-0 bg-transparent"
                  onClick={hadleClickAddModels}
                >
                  <div className="btn-add-models d-flex flex-column align-items-center justify-content-center">
                    <RiEdit2Fill size={32} color="#C8C8C8" />
                    <p className="fw-medium mb-0 small mt-4">
                      Adicionar Novos Modelos
                    </p>
                  </div>
                </button>
                {models.map((model) => (
                  <button
                    key={model.id}
                    type="button"
                    className="col-lg-4 col-xl-3 mb-4 border-0 bg-transparent text-start"
                    onClick={() => hadleClickUpdateModels(model)}
                  >
                    <div className="box-model">
                      <img
                        src={model.photo_url}
                        alt={model.name}
                        className="w-100 mb-2"
                      />
                      <div className="p-4">
                        <p className="mb-0 small mb-1">Nome:</p>
                        <p className="fw-semibold small">
                          <span>{model.name}</span>
                        </p>
                        <p className="small">
                          Marca:{' '}
                          <span className="fw-medium">{model.brand}</span>
                        </p>
                        <p className="mb-0 small">Avaliação:</p>
                        <div className="d-flex">
                          {model.evaluation < 1 && (
                            <TiStarHalf size={20} color="#FFD90F" />
                          )}
                          {(model.evaluation === 1 || model.evaluation > 1) && (
                            <TiStar size={20} color="#FFD90F" />
                          )}
                          {model.evaluation > 1 && model.evaluation < 2 && (
                            <TiStarHalf size={20} color="#FFD90F" />
                          )}
                          {(model.evaluation === 2 || model.evaluation > 2) && (
                            <TiStar size={20} color="#FFD90F" />
                          )}
                          {model.evaluation > 2 && model.evaluation < 3 && (
                            <TiStarHalf size={20} color="#FFD90F" />
                          )}
                          {(model.evaluation === 3 || model.evaluation > 3) && (
                            <TiStar size={20} color="#FFD90F" />
                          )}
                          {model.evaluation > 3 && model.evaluation < 4 && (
                            <TiStarHalf size={20} color="#FFD90F" />
                          )}
                          {(model.evaluation === 4 || model.evaluation > 4) && (
                            <TiStar size={20} color="#FFD90F" />
                          )}
                          {model.evaluation > 4 && model.evaluation < 5 && (
                            <TiStarHalf size={20} color="#FFD90F" />
                          )}
                          {model.evaluation === 5 && (
                            <TiStar size={20} color="#FFD90F" />
                          )}
                        </div>
                      </div>
                    </div>
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal size="xl" show={showAddNews} onHide={handleClose}>
        <Modal.Header className="border-0" closeButton />
        <Modal.Body>
          <Form ref={newFormRef} onSubmit={handleSubmitNews} className="row">
            <div className="col-lg-4 input-image">
              <InputPhoto
                name="image"
                cropImage
                aspect={87.88 / 150}
                cropOptions={{
                  unit: 'px',
                  width: imageCropConfig.width,
                  height: imageCropConfig.height,
                  x: imageCropConfig.x,
                  y: imageCropConfig.y,
                }}
                onChange={(value, _, dimensions) =>
                  handleChangeImageNews(value, dimensions)
                }
                onRemove={handleRemoveImageNews}
              >
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <MdImage size={65} color="#C8C8C8" />
                  <p className="mb-0 fw-medium text-center small">
                    Arraste aqui sua imagem para <br />
                    iniciar o upload
                  </p>
                  <hr className="my-3" />
                  <span className="fw-medium small rounded-pill d-inline-block py-1">
                    Subir imagem
                  </span>
                </div>
              </InputPhoto>
            </div>
            <div className="col-lg-8 px-2 py-4 p-lg-5">
              <Input
                name="title"
                placeholder="Digite um título"
                className="input"
              />
              <Textarea
                name="description"
                placeholder="Descrição da novidade"
                className="textarea"
                height="450px"
              />
              <div className="d-flex justify-content-end">
                <button
                  type="submit"
                  className="btn-submit border-0 bg-transparent d-flex align-items-center justify-content-center"
                >
                  <div className="me-2 d-flex align-items-center justify-content-center">
                    <BiSave size={18} color="#fff" />
                  </div>
                  Salvar
                </button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal size="xl" show={showEditNews} onHide={handleClose}>
        <Modal.Header className="border-0" closeButton />
        <Modal.Body>
          <Form
            ref={updateNewFormRef}
            onSubmit={handleSubmitUpdateNews}
            initialData={newSelected}
            className="row"
          >
            <div
              className={`col-lg-4 input-image position-relative pb-5 ${
                !editNews ? 'saved' : ''
              }`}
            >
              <InputPhoto
                name="image"
                value={newSelected.photo_url}
                cropImage={editNews}
                aspect={87.88 / 150}
                cropOptions={{
                  unit: 'px',
                  width: imageCropConfig.width,
                  height: imageCropConfig.height,
                  x: imageCropConfig.x,
                  y: imageCropConfig.y,
                }}
                onChange={(value, _, dimensions) =>
                  handleChangeImageNews(value, dimensions)
                }
                onRemove={handleRemoveImageNews}
              >
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <MdImage size={65} color="#C8C8C8" />
                  <p className="mb-0 fw-medium text-center small">
                    Arraste aqui sua imagem para <br />
                    iniciar o upload
                  </p>
                  <hr className="my-3" />
                  <span className="fw-medium small rounded-pill d-inline-block py-1">
                    Subir imagem
                  </span>
                </div>
              </InputPhoto>
            </div>
            <div className="col-lg-8 px-2 py-4 p-lg-5">
              <Input
                name="title"
                placeholder="Digite um título"
                className="input"
                disabled={!editNews}
              />
              <Textarea
                name="description"
                placeholder="Descrição da novidade"
                className="textarea"
                height="450px"
                disabled={!editNews}
              />
              <div className="d-flex justify-content-end">
                {editNews ? (
                  <button
                    type="submit"
                    className="btn-submit border-0 bg-transparent d-flex align-items-center justify-content-center"
                  >
                    <div className="me-2 d-flex align-items-center justify-content-center">
                      <BiSave size={18} color="#fff" />
                    </div>
                    Salvar
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn-edit border-0 bg-transparent d-flex align-items-center justify-content-center"
                    onMouseUp={handleClickEditNews}
                  >
                    <div className="me-2">
                      <RiEdit2Fill size={18} color="#fff" />
                    </div>
                    <span className="fw-medium">Editar</span>
                  </button>
                )}
                <button
                  type="button"
                  className="btn-delete border-0 bg-transparent d-flex align-items-center justify-content-center ms-3"
                  onClick={handleClickDeleteNews}
                >
                  <div className="me-2 d-flex align-items-center justify-content-center">
                    <HiOutlineTrash size={18} color="#fff" />
                  </div>
                  <span className="fw-medium">Excluir</span>
                </button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal size="xl" show={showAddModels} onHide={handleClose}>
        <Modal.Header className="border-0" closeButton />
        <Modal.Body>
          <Form onSubmit={handleSubmitModels} className="row">
            <div className="col-lg-4 position-relative pb-5 input-image">
              <InputPhoto
                name="image"
                cropImage
                aspect={180 / 146.54}
                cropOptions={{
                  unit: 'px',
                  width: imageCropConfig.width,
                  height: imageCropConfig.height,
                  x: imageCropConfig.x,
                  y: imageCropConfig.y,
                }}
                onChange={(value, _, dimensions) =>
                  handleChangeImageModels(value, dimensions)
                }
                onRemove={handleRemoveImageModels}
              >
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <MdImage size={65} color="#C8C8C8" />
                  <p className="mb-0 fw-medium text-center small">
                    Arraste aqui sua imagem para <br />
                    iniciar o upload
                  </p>
                  <hr className="my-3" />
                  <span className="fw-medium small rounded-pill d-inline-block py-1">
                    Subir imagem
                  </span>
                </div>
              </InputPhoto>
            </div>
            <div className="col-lg-8 px-2 py-4 p-lg-5">
              <div className="row news">
                <div className="col-lg-6 mb-4">
                  <label className="w-100">
                    <span className="fw-medium d-block mb-1">Nome:</span>
                    <Input name="name" className="input" />
                  </label>
                </div>
                <div className="col-lg-6 mb-4">
                  <label className="w-100">
                    <span className="fw-medium d-block mb-1">Marca:</span>
                    <Input name="brand" className="input" />
                  </label>
                </div>
                <div className="col-lg-6">
                  <label className="w-100">
                    <span className="fw-medium d-block mb-1">Avaliação:</span>
                  </label>
                  <div className="d-flex position-relative evaluation">
                    <ButtonEvaluation
                      type="button"
                      className="border-0 bg-transparent"
                      value={0.5}
                      current_value={evaluationAux || evaluation}
                      onMouseEnter={() => handleMouseEnterEvaluation(0.5)}
                      onMouseLeave={handleMouseLeaveEvaluation}
                      onClick={() => handleSelectEvaluation(0.5)}
                    >
                      <TiStarHalf size={41} color="#E8E7E2" />
                    </ButtonEvaluation>
                    <ButtonEvaluation
                      type="button"
                      className="border-0 bg-transparent"
                      value={1}
                      current_value={evaluationAux || evaluation}
                      onMouseEnter={() => handleMouseEnterEvaluation(1)}
                      onMouseLeave={handleMouseLeaveEvaluation}
                      onClick={() => handleSelectEvaluation(1)}
                    >
                      <TiStarHalf size={41} color="#E8E7E2" />
                    </ButtonEvaluation>
                    <ButtonEvaluation
                      type="button"
                      className="border-0 bg-transparent"
                      value={1.5}
                      current_value={evaluationAux || evaluation}
                      onMouseEnter={() => handleMouseEnterEvaluation(1.5)}
                      onMouseLeave={handleMouseLeaveEvaluation}
                      onClick={() => handleSelectEvaluation(1.5)}
                    >
                      <TiStarHalf size={41} color="#E8E7E2" />
                    </ButtonEvaluation>
                    <ButtonEvaluation
                      type="button"
                      className="border-0 bg-transparent"
                      value={2}
                      current_value={evaluationAux || evaluation}
                      onMouseEnter={() => handleMouseEnterEvaluation(2)}
                      onMouseLeave={handleMouseLeaveEvaluation}
                      onClick={() => handleSelectEvaluation(2)}
                    >
                      <TiStarHalf size={41} color="#E8E7E2" />
                    </ButtonEvaluation>
                    <ButtonEvaluation
                      type="button"
                      className="border-0 bg-transparent"
                      value={2.5}
                      current_value={evaluationAux || evaluation}
                      onMouseEnter={() => handleMouseEnterEvaluation(2.5)}
                      onMouseLeave={handleMouseLeaveEvaluation}
                      onClick={() => handleSelectEvaluation(2.5)}
                    >
                      <TiStarHalf size={41} color="#E8E7E2" />
                    </ButtonEvaluation>
                    <ButtonEvaluation
                      type="button"
                      className="border-0 bg-transparent"
                      value={3}
                      current_value={evaluationAux || evaluation}
                      onMouseEnter={() => handleMouseEnterEvaluation(3)}
                      onMouseLeave={handleMouseLeaveEvaluation}
                      onClick={() => handleSelectEvaluation(3)}
                    >
                      <TiStarHalf size={41} color="#E8E7E2" />
                    </ButtonEvaluation>
                    <ButtonEvaluation
                      type="button"
                      className="border-0 bg-transparent"
                      value={3.5}
                      current_value={evaluationAux || evaluation}
                      onMouseEnter={() => handleMouseEnterEvaluation(3.5)}
                      onMouseLeave={handleMouseLeaveEvaluation}
                      onClick={() => handleSelectEvaluation(3.5)}
                    >
                      <TiStarHalf size={41} color="#E8E7E2" />
                    </ButtonEvaluation>
                    <ButtonEvaluation
                      type="button"
                      className="border-0 bg-transparent"
                      value={4}
                      current_value={evaluationAux || evaluation}
                      onMouseEnter={() => handleMouseEnterEvaluation(4)}
                      onMouseLeave={handleMouseLeaveEvaluation}
                      onClick={() => handleSelectEvaluation(4)}
                    >
                      <TiStarHalf size={41} color="#E8E7E2" />
                    </ButtonEvaluation>
                    <ButtonEvaluation
                      type="button"
                      className="border-0 bg-transparent"
                      value={4.5}
                      current_value={evaluationAux || evaluation}
                      onMouseEnter={() => handleMouseEnterEvaluation(4.5)}
                      onMouseLeave={handleMouseLeaveEvaluation}
                      onClick={() => handleSelectEvaluation(4.5)}
                    >
                      <TiStarHalf size={41} color="#E8E7E2" />
                    </ButtonEvaluation>
                    <ButtonEvaluation
                      type="button"
                      className="border-0 bg-transparent"
                      value={5}
                      current_value={evaluationAux || evaluation}
                      onMouseEnter={() => handleMouseEnterEvaluation(5)}
                      onMouseLeave={handleMouseLeaveEvaluation}
                      onClick={() => handleSelectEvaluation(5)}
                    >
                      <TiStarHalf size={41} color="#E8E7E2" />
                    </ButtonEvaluation>
                  </div>
                </div>
                <div className="col-lg-6 align-self-end">
                  <div className="d-flex justify-content-end">
                    <button
                      type="submit"
                      className="btn-submit border-0 bg-transparent d-flex align-items-center justify-content-center"
                    >
                      <div className="me-2 d-flex align-items-center justify-content-center">
                        <BiSave size={18} color="#fff" />
                      </div>
                      Salvar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal size="xl" show={showEditModels} onHide={handleClose}>
        <Modal.Header className="border-0" closeButton />
        <Modal.Body>
          <Form
            ref={updateModelFormRef}
            onSubmit={handleSubmitUpdateModels}
            initialData={modelSelected}
            className="row"
          >
            <div className="col-lg-4 position-relative pb-5 input-image">
              <InputPhoto
                name="image"
                value={modelSelected.photo_url}
                cropImage
                aspect={180 / 146.54}
                cropOptions={{
                  unit: 'px',
                  width: imageCropConfig.width,
                  height: imageCropConfig.height,
                  x: imageCropConfig.x,
                  y: imageCropConfig.y,
                }}
                onChange={(value, _, dimensions) =>
                  handleChangeImageModels(value, dimensions)
                }
                onRemove={handleRemoveImageModels}
              >
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <MdImage size={65} color="#C8C8C8" />
                  <p className="mb-0 fw-medium text-center small">
                    Arraste aqui sua imagem para <br />
                    iniciar o upload
                  </p>
                  <hr className="my-3" />
                  <span className="fw-medium small rounded-pill d-inline-block py-1">
                    Subir imagem
                  </span>
                </div>
              </InputPhoto>
            </div>
            <div className="col-lg-8 px-2 py-4 p-lg-5">
              <div className="row news">
                <div className="col-lg-6 mb-4">
                  <label className="w-100">
                    <span className="fw-medium d-block mb-1">Nome:</span>
                    <Input name="name" className="input" />
                  </label>
                </div>
                <div className="col-lg-6 mb-4">
                  <label className="w-100">
                    <span className="fw-medium d-block mb-1">Marca:</span>
                    <Input name="brand" className="input" />
                  </label>
                </div>
                <div className="col-lg-6">
                  <label>
                    <span className="fw-medium d-block mb-1">Avaliação:</span>
                  </label>
                  <div className="d-flex position-relative evaluation">
                    <ButtonEvaluation
                      type="button"
                      className="border-0 bg-transparent"
                      value={0.5}
                      current_value={evaluationAux || evaluation}
                      onMouseEnter={() => handleMouseEnterEvaluation(0.5)}
                      onMouseLeave={handleMouseLeaveEvaluation}
                      onClick={() => handleSelectEvaluation(0.5)}
                    >
                      <TiStarHalf size={41} color="#E8E7E2" />
                    </ButtonEvaluation>
                    <ButtonEvaluation
                      type="button"
                      className="border-0 bg-transparent"
                      value={1}
                      current_value={evaluationAux || evaluation}
                      onMouseEnter={() => handleMouseEnterEvaluation(1)}
                      onMouseLeave={handleMouseLeaveEvaluation}
                      onClick={() => handleSelectEvaluation(1)}
                    >
                      <TiStarHalf size={41} color="#E8E7E2" />
                    </ButtonEvaluation>
                    <ButtonEvaluation
                      type="button"
                      className="border-0 bg-transparent"
                      value={1.5}
                      current_value={evaluationAux || evaluation}
                      onMouseEnter={() => handleMouseEnterEvaluation(1.5)}
                      onMouseLeave={handleMouseLeaveEvaluation}
                      onClick={() => handleSelectEvaluation(1.5)}
                    >
                      <TiStarHalf size={41} color="#E8E7E2" />
                    </ButtonEvaluation>
                    <ButtonEvaluation
                      type="button"
                      className="border-0 bg-transparent"
                      value={2}
                      current_value={evaluationAux || evaluation}
                      onMouseEnter={() => handleMouseEnterEvaluation(2)}
                      onMouseLeave={handleMouseLeaveEvaluation}
                      onClick={() => handleSelectEvaluation(2)}
                    >
                      <TiStarHalf size={41} color="#E8E7E2" />
                    </ButtonEvaluation>
                    <ButtonEvaluation
                      type="button"
                      className="border-0 bg-transparent"
                      value={2.5}
                      current_value={evaluationAux || evaluation}
                      onMouseEnter={() => handleMouseEnterEvaluation(2.5)}
                      onMouseLeave={handleMouseLeaveEvaluation}
                      onClick={() => handleSelectEvaluation(2.5)}
                    >
                      <TiStarHalf size={41} color="#E8E7E2" />
                    </ButtonEvaluation>
                    <ButtonEvaluation
                      type="button"
                      className="border-0 bg-transparent"
                      value={3}
                      current_value={evaluationAux || evaluation}
                      onMouseEnter={() => handleMouseEnterEvaluation(3)}
                      onMouseLeave={handleMouseLeaveEvaluation}
                      onClick={() => handleSelectEvaluation(3)}
                    >
                      <TiStarHalf size={41} color="#E8E7E2" />
                    </ButtonEvaluation>
                    <ButtonEvaluation
                      type="button"
                      className="border-0 bg-transparent"
                      value={3.5}
                      current_value={evaluationAux || evaluation}
                      onMouseEnter={() => handleMouseEnterEvaluation(3.5)}
                      onMouseLeave={handleMouseLeaveEvaluation}
                      onClick={() => handleSelectEvaluation(3.5)}
                    >
                      <TiStarHalf size={41} color="#E8E7E2" />
                    </ButtonEvaluation>
                    <ButtonEvaluation
                      type="button"
                      className="border-0 bg-transparent"
                      value={4}
                      current_value={evaluationAux || evaluation}
                      onMouseEnter={() => handleMouseEnterEvaluation(4)}
                      onMouseLeave={handleMouseLeaveEvaluation}
                      onClick={() => handleSelectEvaluation(4)}
                    >
                      <TiStarHalf size={41} color="#E8E7E2" />
                    </ButtonEvaluation>
                    <ButtonEvaluation
                      type="button"
                      className="border-0 bg-transparent"
                      value={4.5}
                      current_value={evaluationAux || evaluation}
                      onMouseEnter={() => handleMouseEnterEvaluation(4.5)}
                      onMouseLeave={handleMouseLeaveEvaluation}
                      onClick={() => handleSelectEvaluation(4.5)}
                    >
                      <TiStarHalf size={41} color="#E8E7E2" />
                    </ButtonEvaluation>
                    <ButtonEvaluation
                      type="button"
                      className="border-0 bg-transparent"
                      value={5}
                      current_value={evaluationAux || evaluation}
                      onMouseEnter={() => handleMouseEnterEvaluation(5)}
                      onMouseLeave={handleMouseLeaveEvaluation}
                      onClick={() => handleSelectEvaluation(5)}
                    >
                      <TiStarHalf size={41} color="#E8E7E2" />
                    </ButtonEvaluation>
                  </div>
                </div>
                <div className="col-lg-6 align-self-end">
                  <div className="d-flex justify-content-end">
                    <button
                      type="submit"
                      className="btn-submit border-0 bg-transparent d-flex align-items-center justify-content-center"
                    >
                      <div className="me-2 d-flex align-items-center justify-content-center">
                        <BiSave size={18} color="#fff" />
                      </div>
                      Salvar
                    </button>
                    <button
                      type="button"
                      className="btn-delete border-0 bg-transparent d-flex align-items-center justify-content-center ms-3"
                      onClick={handleClickDeleteModels}
                    >
                      <div className="me-2 d-flex align-items-center justify-content-center">
                        <HiOutlineTrash size={18} color="#fff" />
                      </div>
                      <span className="fw-medium">Excluir</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default NewsManage;
