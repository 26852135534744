import React, { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { TiStarFullOutline } from 'react-icons/ti';
import { VscArrowSmallRight } from 'react-icons/vsc';
import { BsPlus } from 'react-icons/bs';
import { GoKebabVertical } from 'react-icons/go';

import { Container, Avatar, Score } from './styles';
import FloatProfile from '~/components/FloatProfile';
import Table, { IColumn } from '~/components/Table';
import { RiEdit2Fill } from 'react-icons/ri';
import { HiDownload } from 'react-icons/hi';
import { SiWhatsapp } from 'react-icons/si';
import { Form } from '@unform/web';
import Input from '~/components/Input';
import BoxPlan from '~/components/BoxPlan';
import { FiAlertCircle } from 'react-icons/fi';
import api from '~/services/api';
import { differenceInYears, format, parseISO } from 'date-fns';

import avatar from '~/assets/defaults/default-avatar.svg';

interface IOrder {
  id: string;

  price: string;
  status: string;
}

interface IUserResponse {
  id: string;
  name: string;
  email: string;
  birthdate: string;
  celphone: string;
  avatar?: {
    avatar_url: string;
  };
  address?: null;
  subscription: {
    plan: {
      name: string;
      time: number;
    };
    orders: IOrder[];
  };
}

interface IStatistic {
  id: string;
  user_id: string;
  team_id: string;
  from: string;
  to: string;
  currently: boolean;
  performance_area: string;
  passes: number;
  goal_shooting: number;
  corner: number;
  shot: number;
  impediment: number;
  ball_possession: number;
  yellow_card: number;
  red_card: number;
  goals: number;
  fouls: number;
  penalties: number;
  team: {
    name: string;
  };
}

interface IUser {
  avatar: string;
  name: string;
  email: string;
  celphone: string;
  age: number;
  plan: string;
  payments: number;
  totalPayments: number;
}

interface IParams {
  user_id: string;
}

const MyPerformance: React.FC = () => {
  const params = useParams<IParams>();
  const [user, setUser] = useState({} as IUser);
  const [statistics, setStatistics] = useState<IStatistic[]>([]);

  useEffect(() => {
    api.get<IUserResponse>(`users/${params.user_id}`).then(async (response) => {
      const responseStatistics = await api.get<IStatistic[]>(
        `statistics/users/${params.user_id}`
      );

      const data = responseStatistics.data.map((statistic) => ({
        ...statistic,
        from: format(parseISO(statistic.from), 'dd/MM/yyyy'),
        to: statistic.to
          ? format(parseISO(statistic.to), 'dd/MM/yyyy')
          : 'Atualmente',
      }));

      const nameParts = response.data.name.split(' ');
      setUser({
        avatar: response.data.avatar ? response.data.avatar.avatar_url : avatar,
        name: `${nameParts[0]} ${nameParts[1]}`,
        email: response.data.email,
        celphone: response.data.celphone,
        age: differenceInYears(new Date(), parseISO(response.data.birthdate)),
        plan: response.data.subscription.plan.name,
        payments: response.data.subscription.orders.filter(
          (order) => order.status === 'Paid'
        ).length,
        totalPayments: response.data.subscription.plan.time,
      });
      setStatistics(data);
    });
  }, [params.user_id]);

  return (
    <Container className="py-5">
      {Object.keys(user).length > 0 && (
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-6 order-1 order-lg-0 mb-4 mb-lg-0 d-flex align-items-center">
              <h2 className="fw-semibold">Membros</h2>
            </div>
            <div className="col-lg-6 d-none d-lg-flex justify-content-end pe-lg-5">
              <FloatProfile />
            </div>
            <div className="col-12 mt-5 pe-lg-5">
              <h2 className="h4 mb-4">Dados pessoais</h2>
              <div className="box personal-data p-5 d-flex align-items-center">
                <Avatar src={user.avatar} />
                <div className="d-flex align-items-center data">
                  <div className="row px-5">
                    <div className="col-lg-4 mb-3">
                      <h3 className="h5 fw-medium mb-1">Nome</h3>
                      <p className="mb-0">{user.name}</p>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <h3 className="h5 fw-medium mb-1">E-mail</h3>
                      <p className="mb-0">{user.email}</p>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <h3 className="h5 fw-medium mb-1">Celular</h3>
                      <p className="mb-0">{user.celphone}</p>
                    </div>
                    <div className="col-lg-4">
                      <h3 className="h5 fw-medium mb-1">Idade</h3>
                      <p className="mb-0">{user.age} anos</p>
                    </div>
                    <div className="col-lg-4">
                      <h3 className="h5 fw-medium mb-1">Plano</h3>
                      <p className="mb-0">{user.plan}</p>
                    </div>
                    <div className="col-lg-4 align-self-end">
                      <button
                        type="button"
                        className="btn-whatsapp btn rounded-pill py-1 px-4 align-self-end d-flex align-items-center justify-content-center"
                      >
                        <span>Conversar</span>
                        <SiWhatsapp size={18} color="#fff" className="ms-3" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mt-5 pe-lg-5">
              <h2 className="h4 mb-4">Pagamentos</h2>
              <div className="box px-5 py-4">
                <BoxPlan
                  plan={user.plan}
                  payments={user.payments}
                  totalPayments={user.totalPayments}
                  className="py-3 px-5"
                />
              </div>
            </div>
            <div className="col-12 mt-4 pe-lg-5">
              {statistics.map((statistic) => (
                <div
                  key={statistic.id}
                  className="box px-3 px-lg-4 px-xl-5 py-5 mb-4"
                >
                  <div className="row">
                    <div className="col-lg-4 mb-3 mb-lg-0">
                      <div className="field w-lg-100 w-xl-auto p-4 px-lg-3 px-xl-4 d-flex flex-column">
                        <div>
                          <Avatar
                            src={user.avatar}
                            className="mx-auto mt-n5 mb-4"
                          />
                          <h2 className="h4 text-center text-white fw-semibold mb-xl-4 px-xl-4">
                            {statistic.performance_area}
                          </h2>
                        </div>
                        <div className="d-flex flex-lg-column justify-content-between">
                          <div>
                            <p className="mb-0 text-white fw-light">Time:</p>
                            <p className="text-white fw-medium">
                              {statistic.team.name}
                            </p>
                          </div>
                          <hr className="d-none d-lg-block" />
                          <div className="d-flex">
                            <div className="me-4">
                              <p className="mb-0 text-white fw-light">Desde:</p>
                              <p className="text-white fw-medium">
                                {statistic.from}
                              </p>
                            </div>
                            <div>
                              <p className="mb-0 text-white fw-light">Até:</p>
                              <p className="text-white fw-medium">
                                {statistic.to}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="box px-2 py-3 p-lg-4">
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <h6 className="fw-bold">Estatísticas</h6>
                            <p className="fw-medium subtitle">Meus dados</p>
                          </div>
                        </div>
                        <div className="row my-4 data">
                          <div className="col-6 d-flex justify-content-between align-items-center mt-3 my-lg-3">
                            <p className="fw-medium mb-0">Passes</p>
                            <span className="d-flex align-items-center justify-content-center fw-bold">
                              {statistic.passes.toString().padStart(2, '0')}
                            </span>
                          </div>
                          <div className="col-6 d-flex justify-content-between align-items-center mt-3 my-lg-3">
                            <p className="fw-medium mb-0">Tiro</p>
                            <span className="d-flex align-items-center justify-content-center fw-bold">
                              {statistic.shot.toString().padStart(2, '0')}
                            </span>
                          </div>
                          <div className="col-6 d-flex justify-content-between align-items-center mt-3 my-lg-3">
                            <p className="fw-medium mb-0">Tiro de meta</p>
                            <span className="d-flex align-items-center justify-content-center fw-bold">
                              {statistic.goal_shooting
                                .toString()
                                .padStart(2, '0')}
                            </span>
                          </div>
                          <div className="col-6 d-flex justify-content-between align-items-center mt-3 my-lg-3">
                            <p className="fw-medium mb-0">Impedimento</p>
                            <span className="d-flex align-items-center justify-content-center fw-bold">
                              {statistic.impediment.toString().padStart(2, '0')}
                            </span>
                          </div>
                          <div className="col-6 d-flex justify-content-between align-items-center mt-3 my-lg-3">
                            <p className="fw-medium mb-0">Escanteio</p>
                            <span className="d-flex align-items-center justify-content-center fw-bold">
                              {statistic.corner.toString().padStart(2, '0')}
                            </span>
                          </div>
                          <div className="col-6 d-flex justify-content-between align-items-center mt-3 my-lg-3">
                            <p className="fw-medium mb-0">Posse de bola</p>
                            <span className="d-flex align-items-center justify-content-center fw-bold">
                              {statistic.ball_possession
                                .toString()
                                .padStart(2, '0')}
                            </span>
                          </div>
                        </div>
                        <hr />
                        <div className="row pt-3 mt-n1">
                          <div className="col-lg-4">
                            <p className="small mb-1">Cartões</p>
                            <div className="d-flex mb-4">
                              <div className="sub-box py-2 px-3 d-flex align-items-center justify-content-center w-100 w-lg-50 w-xl-auto">
                                <div className="cards yellow d-flex align-items-center justify-content-center me-2">
                                  <div />
                                </div>
                                <p className="mb-0">
                                  {statistic.yellow_card
                                    .toString()
                                    .padStart(2, '0')}
                                </p>
                              </div>
                              <div className="sub-box py-2 px-3 d-flex align-items-center justify-content-center w-100 w-lg-50 w-xl-auto">
                                <div className="cards red d-flex align-items-center justify-content-center me-2">
                                  <div />
                                </div>
                                <p className="mb-0">
                                  {statistic.red_card
                                    .toString()
                                    .padStart(2, '0')}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-8 d-flex">
                            <div className="w-100 w-lg-auto">
                              <p className="small mb-1">Gols</p>
                              <div className="sub-box py-2 px-3 d-flex align-items-center justify-content-center w-100 w-lg-auto">
                                <p className="mb-0">
                                  {statistic.goals.toString().padStart(2, '0')}
                                </p>
                              </div>
                            </div>
                            <div className="mx-3 mx-lg-4 w-100 w-lg-auto">
                              <p className="small mb-1">Faltas</p>
                              <div className="sub-box py-2 px-3 d-flex align-items-center justify-content-center w-100 w-lg-auto">
                                <p className="mb-0">
                                  {statistic.fouls.toString().padStart(2, '0')}
                                </p>
                              </div>
                            </div>
                            <div className="w-100 w-lg-auto">
                              <p className="small mb-1">Pênaltis</p>
                              <div className="sub-box py-2 px-3 d-flex align-items-center justify-content-center w-100 w-lg-auto">
                                <p className="mb-0">
                                  {statistic.penalties
                                    .toString()
                                    .padStart(2, '0')}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default MyPerformance;
