import styled from 'styled-components';

interface IAvatar {
  src: string;
}

export const Container = styled.div`
  .username {
    color: #414142;
  }

  .area {
    color: #747475;
  }
`;

export const Avatar = styled.div<IAvatar>`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #cccccc;
  border: 1px solid #cccccc;
  height: 46px;
  width: 46px;
  border-radius: 50%;
`;
