import styled from 'styled-components';
import { darken, lighten } from 'polished';
import { Modal as ModalComponent } from 'react-bootstrap';

interface IOptions {
  show: boolean;
}

export const Container = styled.div`
  h2 {
    color: #202020;
  }

  .model-box {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
      linear-gradient(90deg, #0fdfb7 -44.4%, #aaf23c 130.97%);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    position: relative;
    overflow: hidden;

    > div {
      position: relative;
      z-index: 2;

      h5,
      p {
        color: #202020;
      }

      .brand {
        margin-left: 1rem !important;
      }
    }
  }

  .box {
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 10px;

    h6 {
      color: #202020;
    }

    p {
      color: #202020;
    }

    .sub-box {
      background: #f9f9f9;
      border-radius: 6px;

      .cards {
        width: 20px;
        height: 20px;

        div {
          width: 11px;
          height: 16px;
          border-radius: 1px;
          position: relative;

          :before {
            content: '';
            width: 11px;
            height: 16px;
            border-radius: 1px;
            background-color: #2d2e2e;
            position: absolute;
            top: -2px;
            right: -4px;
            display: block;
          }

          :after {
            content: '';
            width: 11px;
            height: 16px;
            border-radius: 1px;
            background-color: #202020;
            position: absolute;
            top: 0px;
            right: 0px;
            display: block;
          }
        }
      }

      .cards.yellow div {
        :before {
          background-color: #ffca18;
        }

        :after {
          background-color: #e5b100;
        }
      }

      .cards.red div {
        :before {
          background-color: #e14640;
        }

        :after {
          background-color: #ce130c;
        }
      }

      + .sub-box {
        margin-left: 5px;
      }
    }
  }

  .my-games {
    > div {
      margin-bottom: -20px;

      button {
        transition-duration: 0.3s;
        color: #a4a4a4;
        border-bottom: 4px solid transparent !important;
      }

      button:hover,
      button.active {
        color: #100f0f;
        border-color: #0d0d0d !important;
      }
    }

    .btn-new {
      border: 1px solid #202020 !important;
      background-color: #202020 !important;
      color: #ffffff !important;

      :hover {
        border-color: ${lighten(0.1, '#202020')} !important;
        background-color: ${lighten(0.1, '#202020')} !important;
      }
    }
  }

  .table-content {
    > div {
      padding: 0;

      > div:nth-child(1) {
        padding: 0;
        border: none;

        .content {
          padding-bottom: 0;

          .tb-header,
          .tb-body {
            .tb-row {
              position: relative;

              > button,
              > div {
                > div {
                  margin-bottom: 10px;
                }
              }

              > div:nth-child(8) {
                position: absolute;
                width: 24px;
                height: 24px;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                right: 20px;
                top: 20px;
              }
            }
          }

          .tb-body {
            .tb-row {
              :nth-child(odd) {
                background: #fbfbfb;
                border-radius: 8px;
              }

              :nth-child(even) {
                background: transparent;
                border-radius: 8px;
              }
            }
          }
        }

        :before,
        :after {
          display: none !important;
        }
      }
      > div:nth-child(2) {
        margin-top: 10px !important;
      }
    }
  }

  @media screen and (min-width: 380px) {
    .model-box {
      > div {
        .brand {
          margin-left: 3rem !important;
        }
      }
    }
  }

  @media screen and (min-width: 991px) {
    .table-content {
      > div {
        > div:nth-child(1) {
          .content {
            .tb-header,
            .tb-body {
              .tb-row {
                > button:nth-child(1),
                > div:nth-child(1) {
                  width: 250px;
                }

                > button:nth-child(3),
                > div:nth-child(3) {
                  width: 100px;
                }

                > button:nth-child(4),
                > div:nth-child(4) {
                  width: 100px;
                }

                > button:nth-child(5),
                > div:nth-child(5) {
                  width: 320px;
                  overflow-wrap: break-word;
                }

                > button:nth-child(6),
                > div:nth-child(6) {
                  width: 100px;
                }

                > button:nth-child(8),
                > div:nth-child(8) {
                  position: unset;
                  width: 50px;
                  height: unset;
                  padding: 15px;
                  display: unset;
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 1450px) {
    .tb-header,
    .tb-body {
      width: 100%;

      .tb-row {
        width: 100%;
      }
    }
  }
`;

export const Username = styled.div`
  div {
    background: rgba(66, 169, 76, 0.1);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
  }

  p {
    color: #202020;
    margin: 0;
  }
`;

export const BtnWhatsapp = styled.a`
  background: #00ab64;

  :hover {
    background: ${darken(0.05, '#00AB64')};
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    border: none;
    border-radius: 10px;
    padding: 1.5rem;

    .close {
      font-size: 24px;
      border: none;
      position: absolute;
      top: 20px;
      right: 40px;
      font-size: 30px;
      background: none;

      .sr-only {
        display: none;
      }
    }

    .modal-title {
      color: #202020;
    }

    h4 {
      color: #000000;
    }

    label {
      span {
        color: #000000;
      }

      .input {
        background: #f0f0f0;
        border: 1px solid #e4e4e4;
        border-radius: 8px;
        height: 46px;

        input {
          color: #202020;

          ::placeholder {
            color: #a4a4a4;
          }
        }
      }
    }

    .btn-submit {
      border: 1px solid #202020 !important;
      background-color: #202020 !important;
      color: #ffffff !important;

      :hover {
        border-color: ${lighten(0.1, '#202020')} !important;
        background-color: ${lighten(0.1, '#202020')} !important;
      }
    }
  }
`;

export const Options = styled.div<IOptions>`
  background: #fbfbfb;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  position: absolute;
  right: 25px;
  transition-duration: 0.3s;
  opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};

  button {
    span {
      font-size: 14px;
    }

    :nth-child(1) {
      span {
        color: #00ab64;
      }
    }

    :nth-child(2) {
      span {
        color: #e93131;
      }
    }
  }
`;
