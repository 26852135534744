import styled, { css } from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

interface IPhotoContainer {
  src?: string;
  width?: number;
  height?: number;
}

interface IUploadPhoto {
  dragEnter: boolean;
}

export const Container = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 10px;
  overflow: hidden;

  .btn-remove-photo {
    position: absolute;
    bottom: 0px;
    left: 20px;
    color: #fff;
    background-color: #e14640 !important;
    border-radius: 10px;
    width: 150px;
  }

  @media screen and (min-width: 576px) {
    .btn-remove-photo {
      bottom: -55px;
    }
  }
`;

export const PhotoContainer = styled.div<IPhotoContainer>`
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};

  ${(props) =>
    props.src
      ? css`
          position: relative;
          border: 2px dashed #d9d9d9;
          box-sizing: border-box;
          height: ${props.height || '200'}px;
          border-radius: 10px;
          background: url(${props.src}), #fff;
          background-position: center;
          background-size: 110%;
          background-repeat: no-repeat;
        `
      : css`
          display: flex;
        `}
`;

export const UploadPhoto = styled.label<IUploadPhoto>`
  position: relative;
  border: 2px dashed #d9d9d9;
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  border-radius: 10px;
  opacity: ${(props) => (props.dragEnter ? 0.7 : 1)};

  * {
    pointer-events: none;
  }

  .camera {
    width: 100%;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    span {
      padding: 0 10px;
      font-weight: normal;
      width: 100%;
      color: #d9d9d9;
      text-align: center;
    }
  }

  @media screen and (min-width: 768px) {
    height: 138px;

    .camera {
      right: 0;
      bottom: 0;
    }
  }
`;

export const TakePhoto = styled.button`
  position: relative;
  border: 2px dashed #d9d9d9;
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  border-radius: 10px;

  .camera {
    width: 100%;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    span {
      padding: 0 10px;
      font-weight: normal;
      width: 100%;
      color: #d9d9d9;
      text-align: center;
    }
  }

  @media screen and (min-width: 768px) {
    height: 138px;

    .camera {
      right: 0;
      bottom: 0;
    }
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.5);
    border: none;
    background: #fff;
    border-radius: 10px;
    padding: 1.5rem;

    h4 {
      font-weight: 600;
      color: #202020;
    }

    .close {
      font-size: 24px;
      border: none;
      position: absolute;
      top: 20px;
      right: 40px;
      font-size: 30px;
      background: none;

      .sr-only {
        display: none;
      }
    }

    .btn-take-photo {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 2px solid transparent;
      color: #ffffff;
      font-weight: bold;
      background: #00ab64;
      position: absolute;
      bottom: 50px;
      left: 50%;
      transform: translateX(-50%);
    }

    .buttons-group {
      button {
        width: 121px;
        height: 40px;
        border-radius: 10px;
        border: 2px solid transparent;
      }

      .btn-cancel,
      .btn-another {
        color: #d9d9d9;
        font-weight: 500;
        border-color: #d9d9d9;
      }

      .btn-submit {
        color: #ffffff;
        font-weight: bold;
        background: #005c37;
      }
    }
  }
`;
