import React, { useCallback, useRef } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import getValidationsErrors from '~/utils/getValidationsErrors';
import { useAuth } from '~/hooks/Auth';

import { Container, Background } from './styles';
import Input from '~/components/Input';

interface IFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const { signIn } = useAuth();
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Informe um e-mail válido')
            .required('O e-mail é obrigatório'),
          password: Yup.string().required('A senha é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { email, password } = data;

        signIn({ email, password });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationsErrors(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro ao logar, verifique seus dados.',
            'error'
          );
        }
      }
    },
    [signIn]
  );

  return (
    <Container className="container-fluid d-flex flex-column justify-content-center py-4 py-lg-0">
      <div className="row h-100">
        <Background className="d-none d-lg-block col-lg-6 ms-auto" />
        <div className="col-12 h-100">
          <div className="container h-100">
            <div className="row h-100 justify-content-end">
              <Form
                ref={formRef}
                onSubmit={handleSubmit}
                className="col-lg-6 align-self-center px-4 px-lg-3"
              >
                <div className="row justify-content-center">
                  <div className="col-lg-8 me-lg-5">
                    <h1 className="fw-semibold">Entrar</h1>
                    <h2 className="fw-normal mb-5 h4">Bem Vindo de volta!</h2>
                    <label className="d-block w-100 mb-4">
                      <span className="d-block mb-2">E-mail</span>
                      <Input
                        type="email"
                        name="email"
                        placeholder="example@email.com"
                        className="input"
                      />
                    </label>
                    <label className="d-block w-100 mb-3">
                      <span className="d-block mb-2">Senha</span>
                      <Input
                        type="password"
                        name="password"
                        placeholder="*******"
                        className="input"
                      />
                    </label>
                    <Link
                      to={`${process.env.PUBLIC_URL}/esqueci-a-senha`}
                      className="forgot-password fw-medium"
                    >
                      Esqueceu a senha?
                    </Link>
                    <div className="pt-3 w-100">
                      <button
                        type="submit"
                        className="btn btn-submit d-block w-100 mt-5 fw-semibold mb-3"
                      >
                        Entrar
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
              <div className="d-none d-lg-block col-lg-6" />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default SignIn;
