import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { TiStarFullOutline } from 'react-icons/ti';
import { VscArrowSmallRight } from 'react-icons/vsc';
import { BsPlus } from 'react-icons/bs';
import { GoKebabVertical } from 'react-icons/go';

import { Container, Avatar, Score } from './styles';
import FloatProfile from '~/components/FloatProfile';
import Table, { IColumn } from '~/components/Table';
import { RiEdit2Fill } from 'react-icons/ri';
import { HiDownload } from 'react-icons/hi';

const MyPerformance: React.FC = () => {
  const [data, setData] = useState([
    {
      id: '1',
      group: 'Meninos do Fut',
      score: '1 - 2',
      team: 'Time Y',
      date: '18 de dezembro de 2022',
    },
    {
      id: '2',
      group: 'Portugal',
      score: '2 - 3',
      team: 'Belgium',
      date: '21 de dezembro de 2022',
    },
    {
      id: '3',
      group: 'Meninos do Fut',
      score: '1 - 2',
      team: 'Time Y',
      date: '23 de dezembro de 2022',
    },
  ]);

  const columns = useMemo<IColumn[]>(
    () => [
      {
        name: 'Grupo',
        selector: 'group',
      },
      {
        name: 'Placar',
        selector: 'score',
        cell: (row) => (
          <Score>
            <p>{row.score}</p>
          </Score>
        ),
      },
      {
        name: 'Time',
        selector: 'team',
      },
      {
        name: 'Data',
        selector: 'date',
      },
      {
        name: '',
        cell: () => (
          <button type="button" className="border-0 bg-transparent">
            <GoKebabVertical size={24} color="#B8B9BA" />
          </button>
        ),
      },
    ],
    []
  );

  return (
    <Container className="py-5">
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-6 order-1 order-lg-0 mb-4 mb-lg-0 d-flex align-items-center">
            <h2 className="fw-semibold">Minha Performance</h2>
          </div>
          <div className="col-lg-6 d-none d-lg-flex justify-content-end pe-lg-5">
            <FloatProfile />
          </div>
          <div className="col-12 mt-4 pe-lg-5">
            <div className="box px-3 px-lg-4 px-xl-5 py-5">
              <div className="row">
                <div className="col-lg-4 mb-3 mb-lg-0">
                  <div className="field w-lg-100 w-xl-auto p-4 px-lg-3 px-xl-4 d-flex flex-column">
                    <div>
                      <Avatar
                        src="http://placeimg.com/200/200/any"
                        className="mx-auto mt-n5 mb-4"
                      />
                      <h2 className="h4 text-center text-white fw-semibold mb-xl-4 px-xl-4">
                        Ataque, meio Campo
                      </h2>
                    </div>
                    <div className="d-flex flex-lg-column justify-content-between">
                      <div>
                        <p className="mb-0 text-white fw-light">Time:</p>
                        <p className="text-white fw-medium">Meninos da Vila</p>
                      </div>
                      <hr className="d-none d-lg-block" />
                      <div>
                        <p className="mb-0 text-white fw-light">Desde:</p>
                        <p className="text-white fw-medium">11/12/2021</p>
                      </div>
                    </div>
                    <div className="d-flex flex-lg-column flex-xl-row mt-auto justify-content-between">
                      <button
                        type="button"
                        className="border-0 rounded-pill fw-medium px-2 py-1 py-lg-2 px-xl-4 mb-lg-3 mb-xl-0 small text-white w-lg-100 w-xl-auto"
                      >
                        <RiEdit2Fill size={18} color="#fff" className="me-1" />
                        <span>Editar</span>
                      </button>
                      <button
                        type="button"
                        className="border-0 rounded-pill fw-medium px-2 py-1 py-lg-2 px-xl-4 small text-white w-lg-100 w-xl-auto"
                      >
                        <HiDownload size={18} color="#fff" className="me-1" />
                        <span>Baixar</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="box px-2 py-3 p-lg-4">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h6 className="fw-bold">Estatísticas</h6>
                        <p className="fw-medium subtitle">Meus dados</p>
                      </div>
                      <button
                        type="button"
                        className="border-0 bg-transparent d-flex align-items-center justify-content-center"
                      >
                        <span className="icon d-flex align-items-center justify-content-center me-3">
                          <RiEdit2Fill size={18} color="#fff" />
                        </span>
                        <span className="fw-medium">Editar</span>
                      </button>
                    </div>
                    <div className="row my-4 data">
                      <div className="col-6 d-flex justify-content-between align-items-center mt-3 my-lg-3">
                        <p className="fw-medium mb-0">Passes</p>
                        <span className="d-flex align-items-center justify-content-center fw-bold">
                          10
                        </span>
                      </div>
                      <div className="col-6 d-flex justify-content-between align-items-center mt-3 my-lg-3">
                        <p className="fw-medium mb-0">Tiro</p>
                        <span className="d-flex align-items-center justify-content-center fw-bold">
                          02
                        </span>
                      </div>
                      <div className="col-6 d-flex justify-content-between align-items-center mt-3 my-lg-3">
                        <p className="fw-medium mb-0">Tiro de meta</p>
                        <span className="d-flex align-items-center justify-content-center fw-bold">
                          01
                        </span>
                      </div>
                      <div className="col-6 d-flex justify-content-between align-items-center mt-3 my-lg-3">
                        <p className="fw-medium mb-0">Impedimento</p>
                        <span className="d-flex align-items-center justify-content-center fw-bold">
                          04
                        </span>
                      </div>
                      <div className="col-6 d-flex justify-content-between align-items-center mt-3 my-lg-3">
                        <p className="fw-medium mb-0">Escanteio</p>
                        <span className="d-flex align-items-center justify-content-center fw-bold">
                          20
                        </span>
                      </div>
                      <div className="col-6 d-flex justify-content-between align-items-center mt-3 my-lg-3">
                        <p className="fw-medium mb-0">Posse de bola</p>
                        <span className="d-flex align-items-center justify-content-center fw-bold">
                          15
                        </span>
                      </div>
                    </div>
                    <hr />
                    <div className="row pt-3 mt-n1">
                      <div className="col-lg-4">
                        <p className="small mb-1">Cartões</p>
                        <div className="d-flex mb-4">
                          <div className="sub-box py-2 px-3 d-flex align-items-center justify-content-center w-100 w-lg-50 w-xl-auto">
                            <div className="cards yellow d-flex align-items-center justify-content-center me-2">
                              <div />
                            </div>
                            <p className="mb-0">03</p>
                          </div>
                          <div className="sub-box py-2 px-3 d-flex align-items-center justify-content-center w-100 w-lg-50 w-xl-auto">
                            <div className="cards red d-flex align-items-center justify-content-center me-2">
                              <div />
                            </div>
                            <p className="mb-0">00</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8 d-flex">
                        <div className="w-100 w-lg-auto">
                          <p className="small mb-1">Gols</p>
                          <div className="sub-box py-2 px-3 d-flex align-items-center justify-content-center w-100 w-lg-auto">
                            <p className="mb-0">10</p>
                          </div>
                        </div>
                        <div className="mx-3 mx-lg-4 w-100 w-lg-auto">
                          <p className="small mb-1">Faltas</p>
                          <div className="sub-box py-2 px-3 d-flex align-items-center justify-content-center w-100 w-lg-auto">
                            <p className="mb-0">05</p>
                          </div>
                        </div>
                        <div className="w-100 w-lg-auto">
                          <p className="small mb-1">Pênaltis</p>
                          <div className="sub-box py-2 px-3 d-flex align-items-center justify-content-center w-100 w-lg-auto">
                            <p className="mb-0">02</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default MyPerformance;
