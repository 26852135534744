import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';
import { lighten } from 'polished';

interface IAvatar {
  src: string;
}

export const Container = styled.div`
  h2 {
    color: #202020;
  }

  .box {
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 24px;

    .input {
      background: #f0f0f0;
      border: 1px solid #e4e4e4;
      border-radius: 8px;

      input {
        color: #202020;

        ::placeholder {
          color: #a4a4a4;
        }
      }
    }

    button.btn-avatar {
      span.icon {
        width: 30px;
        height: 30px;
        background: #00ab64;
        border-radius: 50%;
      }
    }

    button.btn-edit {
      span.icon {
        width: 30px;
        height: 30px;
        background: #b6b7b6;
        border-radius: 50%;
      }
    }

    button.btn-reset-password {
      color: #00ab64;
    }

    button.btn-submit {
      transition-duration: 0.3s;
      background: #202020;
      color: #fff;

      :hover {
        background: ${lighten(0.1, '#202020')};
      }
    }

    label {
      span {
        color: #000000;
      }
    }
  }
`;

export const Avatar = styled.div<IAvatar>`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #cccccc;
  border: 1px solid #cccccc;
  height: 112px;
  width: 112px;
  border-radius: 50%;
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    border: none;
    border-radius: 10px;
    padding: 1.5rem;

    .close {
      font-size: 24px;
      border: none;
      position: absolute;
      top: 20px;
      right: 40px;
      font-size: 30px;
      background: none;

      .sr-only {
        display: none;
      }
    }

    h3.h5 {
      color: #202020;
    }

    button.btn-save {
      background: #005c37;
      width: 150px;
      border-radius: 10px;
    }
  }
`;
