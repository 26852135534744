import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import getValidationsErrors from '~/utils/getValidationsErrors';
import { useAuth } from '~/hooks/Auth';

import { Container, Avatar, Modal } from './styles';
import FloatProfile from '~/components/FloatProfile';
import { Form } from '@unform/web';
import InputPhoto from '~/components/InputPhoto';
import Input from '~/components/Input';
import { RiCameraFill, RiEdit2Fill } from 'react-icons/ri';
import InputMask from '~/components/InputMask';
import { FormHandles } from '@unform/core';
import api from '~/services/api';
import { format, parseISO } from 'date-fns';

import avatar from '~/assets/defaults/default-avatar.svg';

interface IFormData {
  name: string;
  birthdate: string;
  celphone: string;
  email: string;
  password?: string;
  new_password?: string;
}

const Profile: React.FC = () => {
  const { user, updateUser } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const [show, setShow] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingPassword, setIsEditingPassword] = useState(false);
  const [avatarPreview, setAvatarPreview] = useState('');
  const [avatarData, setAvatarData] = useState<File | undefined>(undefined);
  const [userData, setUserData] = useState({} as IFormData);
  const [nameError, setNameError] = useState('');
  const [errorNewPassword, setErrorNewPassword] = useState('');

  useEffect(() => {
    setUserData({
      name: user.fullName,
      email: user.email,
      birthdate: format(parseISO(user.birthdate), 'dd/MM/yyyy'),
      celphone: user.celphone,
    });
    setAvatarPreview(user.avatar ? user.avatar.avatar_url : avatar);
  }, [user.avatar, user.birthdate, user.celphone, user.email, user.fullName]);

  const handleClose = useCallback(() => {
    setShow(false);
    setAvatarData(undefined);
  }, []);

  const handleClickEditAvatar = useCallback(() => {
    setShow(true);
  }, []);

  const handleChangeAvatar = useCallback((file) => {
    setAvatarData(file);
  }, []);

  const handleRemoveAvatar = useCallback(() => {
    setAvatarData(undefined);
  }, []);

  const handleClickSave = useCallback(async () => {
    if (avatarData) {
      let avatar_id = user.avatar?.avatar_id;
      let avatar_url = '';
      if (avatar_id && avatarData) {
        const avatarFormData = new FormData();
        avatarFormData.append('avatar', avatarData);
        const response = await api.put(`avatars/${avatar_id}`, avatarFormData);
        avatar_id = response.data.id;
        avatar_url = response.data.avatar_url;
      } else if (avatarData) {
        const avatarFormData = new FormData();
        avatarFormData.append('avatar', avatarData);
        const response = await api.post('avatars', avatarFormData);
        avatar_id = response.data.id;
        avatar_url = response.data.avatar_url;
      }

      await api.put('admins/profile', {
        avatar_id,
      });

      updateUser({
        ...user,
        avatar: avatar_id
          ? {
              avatar_id,
              avatar_url,
            }
          : undefined,
      });

      setAvatarPreview(URL.createObjectURL(avatarData));
    }
    handleClose();
  }, [avatarData, handleClose, updateUser, user]);

  const handleClickEdit = useCallback(() => {
    setIsEditing(true);
  }, []);

  const handleClickEditPassword = useCallback(() => {
    setIsEditingPassword(true);
  }, []);

  const handleChangeName = useCallback((e) => {
    setNameError('');
    const nameParts = e.target.value.split(' ');
    if (nameParts.length <= 1 || (nameParts.length > 1 && !nameParts[1])) {
      setNameError('Informe seu nome completo');
    }
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        setErrorNewPassword('');
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório'),
          birthdate: Yup.string().required('O nascimento é obrigatório'),
          celphone: Yup.string().required('O celular é obrigatório'),
          email: Yup.string()
            .email('Informe um e-mail válido')
            .required('O e-mail é obrigatório'),
          password: Yup.string(),
          new_password: Yup.string(),
          checkName: Yup.string().when('$checkName', {
            is: (checkName: boolean) => checkName,
            then: Yup.string().required('Informe seu nome completo'),
            otherwise: Yup.string(),
          }),
          checkPassword: Yup.string().when('$checkPassword', {
            is: (checkPassword: boolean) => checkPassword,
            then: Yup.string().required(
              'A nova senha deve ser diferente da senha atual'
            ),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            checkName: isEditing && nameError,
            checkPassword: data.password === data.new_password,
          },
        });

        const { name, birthdate, celphone, email, new_password } = data;

        const newDate = birthdate.split('/');
        const birthdateData = new Date(
          parseInt(newDate[2], 10),
          parseInt(newDate[1], 10) - 1,
          parseInt(newDate[0], 10)
        );

        const formData = {
          name,
          birthdate: birthdateData,
          celphone,
          email,
          password: new_password,
        };

        await api.put('admins/profile', formData);

        const nameParts = name.split(' ');
        updateUser({
          ...user,
          name: `${nameParts[0]} ${nameParts[1]}`,
          fullName: name,
          email,
          birthdate: format(birthdateData, 'yyyy-MM-dd'),
          celphone,
        });

        setIsEditing(false);
        setIsEditingPassword(false);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationsErrors(error);
          formRef.current?.setErrors(errors);
          if (errors.checkPassword) {
            setErrorNewPassword(errors.checkPassword);
          }
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro ao te cadastrar, verifique seus dados.',
            'error'
          );
        }
      }
    },
    [isEditing, nameError, updateUser, user]
  );

  return (
    <Container className="py-5">
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-6 mt-4 mt-lg-0">
            <h2 className="fw-semibold">Perfil</h2>
          </div>
          <div className="col-lg-6 d-none d-lg-flex justify-content-end pe-lg-5">
            <FloatProfile />
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12 pe-lg-5">
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              className="box p-4  p-lg-5"
              initialData={userData}
            >
              <div className="row">
                <div className="col-lg-4 mb-5 mb-lg-0">
                  <h2 className="h6 fw-normal mb-4">Foto de perfil</h2>
                  <button
                    type="button"
                    className="border-0 bg-transparent btn-avatar d-flex flex-lg-column justify-content-between justify-content-lg-start align-items-center w-100 w-lg-auto"
                    onClick={handleClickEditAvatar}
                  >
                    <Avatar src={avatarPreview} />
                    <div className="border-0 bg-transparent d-flex align-items-center justify-content-center btn-edit mt-3">
                      <span className="icon d-flex align-items-center justify-content-center me-2">
                        <RiCameraFill size={15} color="#fff" />
                      </span>
                      <span className="fw-medium">Alterar</span>
                    </div>
                  </button>
                </div>
                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-12 mb-3">
                      {!isEditing && (
                        <button
                          type="button"
                          className="border-0 bg-transparent d-flex align-items-center justify-content-center btn-edit ms-auto"
                          onClick={handleClickEdit}
                        >
                          <span className="icon d-flex align-items-center justify-content-center me-3">
                            <RiEdit2Fill size={18} color="#fff" />
                          </span>
                          <span className="fw-medium">Editar</span>
                        </button>
                      )}
                    </div>
                    <div className="col-12 mb-3">
                      <label className="d-block">
                        <span className="fw-medium mb-2 d-block">Nome</span>
                        <Input
                          name="name"
                          className="input"
                          onChange={handleChangeName}
                          disabled={!isEditing}
                          errorData={nameError}
                        />
                      </label>
                    </div>
                    <div className="col-12 mb-3">
                      <label className="d-block">
                        <span className="fw-medium mb-2 d-block">E-mail</span>
                        <Input
                          type="email"
                          name="email"
                          className="input"
                          disabled={!isEditing}
                        />
                      </label>
                    </div>
                    <div className="col-lg-6 mb-3">
                      <div className="d-block">
                        <label>
                          <span className="fw-medium mb-2 d-block">
                            Data de nascimento
                          </span>
                        </label>
                        <InputMask
                          kind="custom"
                          options={{
                            mask: '99/99/9999',
                          }}
                          name="birthdate"
                          className="input"
                          value={userData.birthdate}
                          disabled={!isEditing}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 mb-3">
                      <div className="d-block">
                        <label>
                          <span className="fw-medium mb-2 d-block">
                            Celular
                          </span>
                        </label>
                        <InputMask
                          kind="cel-phone"
                          name="celphone"
                          placeholder="(00) 00000-0000"
                          className="input"
                          value={userData.celphone}
                          disabled={!isEditing}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 mt-5 mb-3">
                      <label className="d-block">
                        <span className="fw-medium mb-2 d-block">
                          Senha atual:
                        </span>
                        <Input
                          type="password"
                          name="password"
                          className="input"
                          disabled={!isEditingPassword}
                        />
                      </label>
                      {!isEditingPassword && (
                        <button
                          type="button"
                          className="bg-transparent border-0 mt-2 btn-reset-password fw-semibold"
                          onClick={handleClickEditPassword}
                        >
                          Redefinir senha
                        </button>
                      )}
                    </div>
                    {isEditingPassword && (
                      <div className="col-lg-6 mt-lg-5 mb-3">
                        <label className="d-block">
                          <span className="fw-medium mb-2 d-block">
                            Nova Senha:
                          </span>
                          <Input
                            type="password"
                            name="new_password"
                            className="input"
                            disabled={!isEditingPassword}
                            errorData={errorNewPassword}
                          />
                        </label>
                      </div>
                    )}
                    {(isEditing || isEditingPassword) && (
                      <div className="col-12 d-flex justify-content-end mt-4">
                        <button
                          type="submit"
                          className="border-0 rounded-pill btn-submit px-4 py-2 fw-semibold"
                        >
                          Salvar
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <Modal size="xl" show={show} onHide={handleClose} backdrop="static">
        <Modal.Header className="border-0" closeButton>
          <h3 className="h5">Alterar foto de perfil</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <Form onSubmit={(data) => console.log(data)} className="col-12">
              <InputPhoto
                name="avatar"
                value={avatarPreview}
                cropImage
                takePhoto
                aspect={1}
                cropOptions={
                  !avatarData
                    ? {
                        unit: 'px',
                        width: 200,
                        height: 200,
                        x: 0,
                        y: 0,
                      }
                    : undefined
                }
                onChange={handleChangeAvatar}
                onRemove={handleRemoveAvatar}
              />
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer className={`border-0 ${!avatarData ? 'pb-5' : ''}`}>
          {avatarData && (
            <button
              type="button"
              className="border-0 fw-medium px-3 py-2 text-white btn-save"
              onClick={handleClickSave}
            >
              <span>Salvar</span>
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Profile;
