import React, { useEffect, useState } from 'react';
import { BiBarcode } from 'react-icons/bi';
import { useLocation } from 'react-router-dom';

import { Container } from './styles';

import StarFullOutline from '~/assets/icons/StarFullOutline.svg';

interface IBoxPlan {
  plan: string;
  payments: number;
  totalPayments: number;
  className?: string;
}

const BoxPlan: React.FC<IBoxPlan> = ({
  plan,
  payments,
  totalPayments,
  className,
}) => {
  return (
    <Container className={`box-plan ${className}`}>
      <div className="d-flex align-items-center justify-content-between flex-wrap">
        <div className="d-flex align-items-center flex-wrap">
          <img src={StarFullOutline} alt="star full outline" />
          <p className="ms-2 mb-0 me-lg-2 pe-3 pe-sm-0">Plano de Assinatura</p>
          <span className="px-3 py-1 fw-semibold plan-name">{plan}</span>
        </div>
        <p className="mb-0 fw-medium">
          Parcelas pagas: {payments}/
          <b className="big fw-semibold">{totalPayments}</b>
        </p>
      </div>
    </Container>
  );
};

export default BoxPlan;
