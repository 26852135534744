import React, { PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import api from '~/services/api';
import { useAuth } from '~/hooks/Auth';

import { Container } from './styles';
import Menu from '~/components/Menu';

const DefaultLayout: React.FC = ({ children }) => {
  const { signOut } = useAuth();
  const location = useLocation();

  useEffect(() => {
    // api.get('check-login').catch(() => {
    //   signOut();
    // });
  }, [signOut, location.pathname]);

  return (
    <Container>
      <Menu />
      <div className="page-content">{children}</div>
    </Container>
  );
};

export default DefaultLayout;
