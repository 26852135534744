import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .menu {
    width: 100%;
  }

  .page-content {
    width: 100%;
  }

  @media screen and (min-width: 992px) {
    flex-direction: row;

    .menu {
      width: 25%;
    }

    .page-content {
      width: 75%;
    }
  }

  @media screen and (min-width: 1200px) {
    flex-direction: row;

    .menu {
      width: 20%;
    }

    .page-content {
      width: 80%;
    }
  }
`;
