import React, { useCallback, useEffect, useState } from 'react';
import { RiEdit2Fill, RiInformationFill } from 'react-icons/ri';
import { Form } from '@unform/web';
import { BsPlus } from 'react-icons/bs';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import getValidationsErrors from '~/utils/getValidationsErrors';

import { Container, Modal } from './styles';
import FloatProfile from '~/components/FloatProfile';
import Input from '~/components/Input';
import InputMask from '~/components/InputMask';
import Textarea from '~/components/Textarea';
import api from '~/services/api';
import { HiOutlineTrash } from 'react-icons/hi';
import Toast from '~/utils/toast';

interface IFormData {
  name: string;
  time: string;
  recommended: string;
  description: string;
  price: string;
  pairs: string;
  order: string;
}

interface IError {
  name: string;
  time: string;
  recommended: string;
  description: string;
  price: string;
  pairs: string;
  order: string;
}

interface IErrors {
  [key: string]: IError;
}

interface IPlanResponse {
  id: string;
  name: string;
  time: string;
  recommended: boolean;
  description: string;
  price: string;
  pairs: number;
  order: number;
}

interface IPlan {
  id: string;
  name: string;
  time: string;
  recommended: boolean;
  description: string;
  price: string;
  pairs: number;
  order: number;
  saved: boolean;
  edit: boolean;
  new: boolean;
}

const Subscriptions: React.FC = () => {
  const [show, setShow] = useState(false);
  const [plans, setPlans] = useState<IPlan[]>([]);
  const [errorsByPlans, setErrorsByPlans] = useState({} as IErrors);

  useEffect(() => {
    api.get<IPlanResponse[]>('plans').then((response) => {
      const data = response.data.map<IPlan>((plan) => ({
        ...plan,
        price: parseFloat(plan.price).toFixed(2),
        new: false,
        edit: false,
        saved: true,
      }));
      setPlans(data);
    });
  }, []);

  const handleClick = useCallback(() => {
    setShow(true);
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const handleClickAddPlan = useCallback(() => {
    const newPlan: IPlan = {
      id: plans.length.toFixed(),
      name: '',
      time: '',
      recommended: false,
      description: '',
      price: '',
      pairs: 1,
      order: plans.length + 1,
      saved: false,
      edit: false,
      new: true,
    };

    setPlans((state) => [newPlan, ...state]);
  }, [plans.length]);

  const handleChangeName = useCallback(
    (e, plan_id) => {
      const newPlans = plans.slice();
      const planIndex = newPlans.findIndex((plan) => plan.id === plan_id);

      if (planIndex >= 0) {
        newPlans[planIndex].name = e.target.value;
      }

      setPlans(newPlans);
    },
    [plans]
  );

  const handleChangeTime = useCallback(
    (e, plan_id) => {
      const newPlans = plans.slice();
      const planIndex = newPlans.findIndex((plan) => plan.id === plan_id);

      if (planIndex >= 0) {
        newPlans[planIndex].time = e.target.value;
      }

      setPlans(newPlans);
    },
    [plans]
  );

  const handleChangeToggle = useCallback(
    (e, plan_id) => {
      const newPlans = plans.slice();
      const planIndex = newPlans.findIndex((plan) => plan.id === plan_id);

      if (planIndex >= 0) {
        newPlans[planIndex].recommended = e.target.checked;
      }

      setPlans(newPlans);
    },
    [plans]
  );

  const handleChangeDescription = useCallback(
    (e, plan_id) => {
      const newPlans = plans.slice();
      const planIndex = newPlans.findIndex((plan) => plan.id === plan_id);

      if (planIndex >= 0) {
        newPlans[planIndex].description = e.target.value;
      }

      setPlans(newPlans);
    },
    [plans]
  );

  const handleClickEdit = useCallback(
    (plan_id) => {
      const newPlans = plans.slice();
      const planIndex = newPlans.findIndex((plan) => plan.id === plan_id);

      if (planIndex >= 0) {
        newPlans[planIndex].edit = true;
        newPlans[planIndex].saved = false;
      }

      setTimeout(() => {
        setPlans(newPlans);
      }, 100);
    },
    [plans]
  );

  const handleSubmit = useCallback(
    async (data: IFormData, plan_id: string) => {
      const newErrorsByPlans = JSON.parse(
        JSON.stringify(errorsByPlans)
      ) as IErrors;
      if (newErrorsByPlans[plan_id]) {
        newErrorsByPlans[plan_id] = {
          name: '',
          time: '',
          recommended: '',
          description: '',
          price: '',
          pairs: '',
          order: '',
        };
      }
      try {
        const schema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório'),
          time: Yup.string().required('O Tempo do plano é obrigatório'),
          recommended: Yup.string().required('O nome é obrigatório'),
          description: Yup.string().required('A descrição é obrigatória'),
          price: Yup.string().required('O preço é obrigatório'),
          pairs: Yup.string().required('A quatidade de pares é obrigatória'),
          order: Yup.string().required('A ordem é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { name, description, time, price, pairs, order } = data;

        const newPlans = plans.slice();
        const planIndex = newPlans.findIndex((plan) => plan.id === plan_id);

        if (planIndex >= 0) {
          const formData = {
            name,
            description,
            price: parseFloat(
              price.replace('R$', '').replaceAll('.', '').replace(',', '.')
            ),
            time,
            pairs,
            recommended: newPlans[planIndex].recommended,
            order,
          };

          if (newPlans[planIndex].new) {
            const response = await api.post('plans', formData);
            newPlans[planIndex].id = response.data.id;
          } else if (newPlans[planIndex].edit) {
            await api.put(`plans/${plan_id}`, formData);
          }

          newPlans[planIndex].name = name;
          newPlans[planIndex].name = name;
          newPlans[planIndex].time = time;
          newPlans[planIndex].description = description;
          newPlans[planIndex].price = price;
          newPlans[planIndex].pairs = parseInt(pairs, 10);
          newPlans[planIndex].order = parseInt(order, 10);
          newPlans[planIndex].saved = true;
          newPlans[planIndex].new = false;
          newPlans[planIndex].edit = false;
        }

        newPlans.sort((a, b) => {
          return a.order - b.order;
        });

        setPlans(newPlans);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationsErrors(error);

          newErrorsByPlans[plan_id] = {
            name: errors.name || '',
            time: errors.time || '',
            recommended: errors.recommended || '',
            description: errors.description || '',
            price: errors.price || '',
            pairs: errors.pairs || '',
            order: errors.order || '',
          };
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro ao logar, verifique seus dados.',
            'error'
          );
        }
      } finally {
        setErrorsByPlans(newErrorsByPlans);
      }
    },
    [errorsByPlans, plans]
  );

  const handleClickDeletePlan = useCallback(
    (plan) => {
      Swal.fire({
        title: 'Deseja deletar esse plano?',
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#e51810',
        cancelButtonColor: '#202020',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            if (plan.id.split('-').length > 1) {
              await api.delete(`plans/${plan.id}`);
            }

            const newPlans = plans.filter(
              (planData) => planData.id !== plan.id
            );
            setPlans(newPlans);

            Toast.fire({
              icon: 'success',
              title: 'Plano deletado!',
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [plans]
  );

  return (
    <Container className="py-5">
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-6 order-1 order-lg-0 mb-4 mb-lg-0 d-flex align-items-center">
            <h2 className="fw-semibold">Assinaturas</h2>
          </div>
          <div className="col-lg-6 d-none d-lg-flex justify-content-end pe-lg-5">
            <FloatProfile />
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12 pe-lg-5">
            <div className="row">
              <div className="col-lg-6">
                <div className="d-flex justify-content-between align-items-center">
                  <h2 className="mb-4 h5 fw-semibold">Planos cadastrados</h2>
                </div>
              </div>
              <div className="col-lg-6 pe-lg-5">
                <button
                  type="button"
                  className="border-0 bg-transparent d-flex align-items-center ms-auto"
                  onClick={handleClick}
                >
                  <RiInformationFill
                    size={24}
                    color="#707070"
                    className="me-2"
                  />
                  <span>Ver detalhes</span>
                </button>
              </div>
            </div>
            <div className="row align-items-center">
              {plans.map((plan) => (
                <div key={plan.id} className="col-lg-12 col-xl-4 mb-4">
                  <div
                    className={`box px-4 ${
                      plan.recommended ? ' py-4 recommended' : 'py-3'
                    }`}
                  >
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <p className="plan-name mb-0 rounded-pill px-3 py-1 fw-semibold">
                        {plan.name}
                      </p>
                      {plan.recommended && (
                        <p className="alert-recommended rounded-pill px-3 py-1 mb-0 fw-semibold">
                          Recomendado
                        </p>
                      )}
                    </div>
                    <p className="duration display-3 fw-semibold mb-0">
                      <span className="big">
                        {plan.time.toString().padStart(2, '0')}
                      </span>
                    </p>
                    <p className="month h4 fw-light mb-5">
                      <span className="big">Meses</span>
                    </p>
                    <p className="description">{plan.description}</p>
                    <button
                      type="button"
                      className="border-0 fw-medium mt-3 h5 w-100 py-3"
                    >
                      Saiba mais
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header className="border-0" closeButton>
          <h2 className="modal-title fw-semibold h2">Gerenciar planos</h2>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 mb-4">
              <button
                type="button"
                className="w-100 d-flex align-items-center justify-content-center btn-new py-2"
                onClick={handleClickAddPlan}
              >
                <BsPlus size={24} color="#202020" />
                <span className="fw-medium mb-0 ms-3">Novo Plano</span>
              </button>
            </div>
            {plans.map((plan) => (
              <Form
                key={plan.id}
                onSubmit={(data: IFormData) => handleSubmit(data, plan.id)}
                initialData={plan}
                className="col-12"
              >
                <div className="box p-4">
                  <div className="row align-items-end">
                    <div className="col-lg-12">
                      <div className="row">
                        <label className="col-lg-4 mb-4">
                          <span className="fw-medium d-block mb-1">Nome:</span>
                          <Input
                            name="name"
                            className="input"
                            value={plan.name}
                            onChange={(e) => handleChangeName(e, plan.id)}
                            disabled={plan.saved}
                            errorData={
                              errorsByPlans[plan.id]
                                ? errorsByPlans[plan.id].name
                                : ''
                            }
                          />
                        </label>
                        <label className="col-lg-3 mb-4">
                          <span className="fw-medium d-block mb-1">
                            Tempo do plano:
                          </span>
                          <Input
                            type="number"
                            name="time"
                            className="input"
                            value={plan.time}
                            onChange={(e) => handleChangeTime(e, plan.id)}
                            disabled={plan.saved}
                            errorData={
                              errorsByPlans[plan.id]
                                ? errorsByPlans[plan.id].time
                                : ''
                            }
                          />
                        </label>
                        <div className="col-lg-5 mb-4">
                          <div className="row">
                            <div className="col-lg-4">
                              <label className="fw-medium d-block mb-1">
                                <span>Recomendado?</span>
                              </label>
                              <label className="d-flex align-items-center">
                                <div
                                  className={`me-2 toggle ${
                                    plan.recommended ? 'checked' : ''
                                  }`}
                                />
                                <span>{plan.recommended ? 'Sim' : 'Não'}</span>
                                <Input
                                  type="checkbox"
                                  name="recommended"
                                  className="d-none"
                                  onChange={(e) =>
                                    handleChangeToggle(e, plan.id)
                                  }
                                  disabled={plan.saved}
                                  checked={plan.recommended}
                                  errorData={
                                    errorsByPlans[plan.id]
                                      ? errorsByPlans[plan.id].recommended
                                      : ''
                                  }
                                />
                              </label>
                            </div>
                            <label className="col-lg-4 mb-4">
                              <span className="fw-medium d-block mb-1">
                                Pares:
                              </span>
                              <Input
                                type="number"
                                name="pairs"
                                min={1}
                                className="input"
                                disabled={plan.saved}
                              />
                            </label>
                            <label className="col-lg-4 mb-4">
                              <span className="fw-medium d-block mb-1">
                                Ordem:
                              </span>
                              <Input
                                type="number"
                                name="order"
                                min={1}
                                className="input"
                                disabled={plan.saved}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-9">
                      <div className="row">
                        <label className="col-lg-8 mb-4">
                          <span className="fw-medium d-block mb-1">
                            Descrição:
                          </span>
                          <Textarea
                            name="description"
                            className="input"
                            value={plan.description}
                            onChange={(e) =>
                              handleChangeDescription(e, plan.id)
                            }
                            disabled={plan.saved}
                            errorData={
                              errorsByPlans[plan.id]
                                ? errorsByPlans[plan.id].description
                                : ''
                            }
                          />
                        </label>
                        <div className="col-lg-4 mb-4">
                          <label>
                            <span className="fw-medium d-block mb-1">
                              Valor:
                            </span>
                          </label>
                          <InputMask
                            kind="money"
                            name="price"
                            className="input w-100"
                            value={plan.price}
                            disabled={plan.saved}
                            errorData={
                              errorsByPlans[plan.id]
                                ? errorsByPlans[plan.id].price
                                : ''
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 d-flex">
                      <button
                        type="button"
                        className="btn-delete border-0 bg-transparent d-flex align-items-center justify-content-center me-3"
                        onClick={() => handleClickDeletePlan(plan)}
                      >
                        <div className="me-2 d-flex align-items-center justify-content-center">
                          <HiOutlineTrash size={18} color="#fff" />
                        </div>
                        <span className="fw-medium">Excluir</span>
                      </button>
                      {!plan.saved ? (
                        <button
                          type="submit"
                          className="btn btn-submit rounded-pill px-lg-5 fw-medium d-flex align-items-center justify-content-center mb-0 ms-auto"
                        >
                          Salvar
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn-edit border-0 bg-transparent ms-auto d-flex align-items-center justify-content-center"
                          onClick={() => handleClickEdit(plan.id)}
                        >
                          <div className="me-2">
                            <RiEdit2Fill size={18} color="#fff" />
                          </div>
                          <span className="fw-medium">Editar</span>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0" />
      </Modal>
    </Container>
  );
};

export default Subscriptions;
