/* eslint-disable import/no-duplicates */
/* eslint-disable no-restricted-syntax */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { TiStarFullOutline } from 'react-icons/ti';
import { SiWhatsapp } from 'react-icons/si';
import { BsPlus } from 'react-icons/bs';
import { GoKebabVertical } from 'react-icons/go';
import { RiUserAddFill, RiUserFill } from 'react-icons/ri';
import { endOfMonth, format, parseISO, startOfMonth } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import Swal from 'sweetalert2';
import * as Yup from 'yup';

import getValidationsErrors from '~/utils/getValidationsErrors';

import { Container, Username, BtnWhatsapp, Modal, Options } from './styles';
import FloatProfile from '~/components/FloatProfile';
import Table, { IColumn } from '~/components/Table';

import soccerShoe from '~/assets/icons/soccer_shoe.svg';
import { Form } from '@unform/web';
import Input from '~/components/Input';
import Select, { IOption } from '~/components/Select';
import InputMask from '~/components/InputMask';
import { HiOutlineTrash } from 'react-icons/hi';
import { useAuth } from '~/hooks/Auth';
import api from '~/services/api';
import Toast from '~/utils/toast';
import { FormHandles } from '@unform/core';
import axios from 'axios';

interface IPlan {
  id: string;
  name: string;
}

interface ILeadResponse {
  id: string;
  name: string;
  email: string;
  age: string;
  celphone: string;
  brand: string;
  size: string;
  created_at: string;
}

interface ILead {
  id: string;
  name: string;
  email: string;
  age: string;
  celphone: string;
  brand: string;
  size: string;
  date: string;
}

interface IAddressResponse {
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
}

interface IAddress {
  street: string;
  neighborhood: string;
  city: string;
  state: string;
  complement: string;
}

interface IFormData {
  name: string;
  email: string;
  birthdate: string;
  celphone: string;
  zipcode: string;
  street: string;
  number: string;
  neighborhood: string;
  city: string;
  state: string;
  complement: string;
  plan: string;
  size: string;
}

const Dashboard: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { user } = useAuth();
  const [leads, setLeads] = useState<ILead[]>([]);
  const [show, setShow] = useState(false);
  const [showOptionForUser, setShowOptionForUser] = useState('');
  const [subscriptions, setSubscriptions] = useState('000');
  const [newUsers, setNewUsers] = useState('00');
  const [totalUsers, setTotalUsers] = useState('000');
  const [totalPlans, setTotalPlans] = useState('00');
  const [leadSelected, setLeadSelected] = useState<ILead | undefined>(
    undefined
  );
  const [plans, setPlans] = useState<IOption[]>([]);
  const [nameError, setNameError] = useState('');
  const [address, setAddress] = useState<IAddress>({
    street: '',
    neighborhood: '',
    city: '',
    state: '',
    complement: '',
  });
  const [swalShowed, setSwalShowed] = useState(false);

  useEffect(() => {
    if (swalShowed) {
      const element = document.getElementById('url-copy');
      if (element) {
        element.addEventListener('click', (e) => {
          const elem = e.target as HTMLDivElement;
          const textarea = document.createElement('textarea');
          textarea.style.opacity = '0';
          textarea.value = elem.innerText;

          document.body.appendChild(textarea);
          textarea.select();

          try {
            document.execCommand('copy');
            Toast.fire({
              icon: 'success',
              title: 'Url Copiada!',
            }).finally(() => {
              setSwalShowed(false);
            });
          } catch (err) {
            console.log(err);
          }

          window.getSelection()?.removeAllRanges();
        });
      }
    }
  }, [swalShowed]);

  useEffect(() => {
    api.get('subscriptions').then(async (responseSubscriptions) => {
      setSubscriptions(
        responseSubscriptions.data.length.toString().padStart(3, '0')
      );

      const responseNewUsers = await api.get('users', {
        params: {
          initialDate: startOfMonth(new Date()),
          endDate: endOfMonth(new Date()),
        },
      });
      setNewUsers(
        responseNewUsers.data.data.length.toString().padStart(2, '0')
      );

      const responseUsers = await api.get('users');
      setTotalUsers(responseUsers.data.data.length.toString().padStart(3, '0'));

      const responsePlans = await api.get<IPlan[]>('plans');
      const data = responsePlans.data.map<IOption>((plan) => ({
        id: plan.id,
        value: plan.name,
        selected: false,
        notSelectable: false,
      }));

      data.unshift({
        id: undefined,
        value: 'Selecione',
        selected: true,
        notSelectable: true,
      });
      setPlans(data);
      setTotalPlans(responsePlans.data.length.toString().padStart(2, '0'));
    });
  }, []);

  useEffect(() => {
    window.addEventListener('click', (e) => {
      const elements = document.getElementsByClassName(
        'options-group'
      ) as unknown as HTMLDivElement[];
      if (elements.length > 0) {
        let isInside = false;
        for (const element of elements) {
          const target = e.target as Node;
          if (element.contains(target)) {
            isInside = true;
          }
        }

        if (!isInside) {
          setShowOptionForUser('');
        }
      }
    });
  }, []);

  useEffect(() => {
    api.get<ILeadResponse[]>('leads').then((response) => {
      const data = response.data.map<ILead>((lead) => ({
        id: lead.id,
        name: lead.name,
        email: lead.email,
        celphone: lead.celphone
          .replace('(', '')
          .replace(')', '')
          .replace(' ', '')
          .replace('-', ''),
        age: lead.age,
        brand: lead.brand,
        size: lead.size,
        date: format(parseISO(lead.created_at), "dd 'de' MMMM 'de' yyyy", {
          locale: ptBR,
        }),
      }));

      setLeads(data);
    });
  }, []);

  const states = useMemo<IOption[]>(() => {
    return [
      {
        id: undefined,
        value: 'Selecionar',
        selected: !address.state,
        notSelectable: true,
      },
      {
        id: 'AC',
        value: 'Acre',
        selected: address.state === 'AC',
        notSelectable: false,
      },
      {
        id: 'AL',
        value: 'Alagoas',
        selected: address.state === 'AL',
        notSelectable: false,
      },
      {
        id: 'AP',
        value: 'Amapá',
        selected: address.state === 'AP',
        notSelectable: false,
      },
      {
        id: 'AM',
        value: 'Amazonas',
        selected: address.state === 'AM',
        notSelectable: false,
      },
      {
        id: 'BA',
        value: 'Bahia',
        selected: address.state === 'BA',
        notSelectable: false,
      },
      {
        id: 'CE',
        value: 'Ceará',
        selected: address.state === 'CE',
        notSelectable: false,
      },
      {
        id: 'DF',
        value: 'Distrito Federal',
        selected: address.state === 'DF',
        notSelectable: false,
      },
      {
        id: 'ES',
        value: 'Espírito Santo',
        selected: address.state === 'ES',
        notSelectable: false,
      },
      {
        id: 'GO',
        value: 'Goiás',
        selected: address.state === 'GO',
        notSelectable: false,
      },
      {
        id: 'MA',
        value: 'Maranhão',
        selected: address.state === 'MA',
        notSelectable: false,
      },
      {
        id: 'MT',
        value: 'Mato Grosso',
        selected: address.state === 'MT',
        notSelectable: false,
      },
      {
        id: 'MS',
        value: 'Mato Grosso do Sul',
        selected: address.state === 'MS',
        notSelectable: false,
      },
      {
        id: 'MG',
        value: 'Minas Gerais',
        selected: address.state === 'MG',
        notSelectable: false,
      },
      {
        id: 'PA',
        value: 'Pará',
        selected: address.state === 'PA',
        notSelectable: false,
      },
      {
        id: 'PB',
        value: 'Paraíba',
        selected: address.state === 'PB',
        notSelectable: false,
      },
      {
        id: 'PR',
        value: 'Paraná',
        selected: address.state === 'PR',
        notSelectable: false,
      },
      {
        id: 'PE',
        value: 'Pernambuco',
        selected: address.state === 'PE',
        notSelectable: false,
      },
      {
        id: 'PI',
        value: 'Piauí',
        selected: address.state === 'PI',
        notSelectable: false,
      },
      {
        id: 'RJ',
        value: 'Rio de Janeiro',
        selected: address.state === 'RJ',
        notSelectable: false,
      },
      {
        id: 'RN',
        value: 'Rio Grande do Norte',
        selected: address.state === 'RN',
        notSelectable: false,
      },
      {
        id: 'RS',
        value: 'Rio Grande do Sul',
        selected: address.state === 'RS',
        notSelectable: false,
      },
      {
        id: 'RO',
        value: 'Rondônia',
        selected: address.state === 'RO',
        notSelectable: false,
      },
      {
        id: 'RR',
        value: 'Roraima',
        selected: address.state === 'RR',
        notSelectable: false,
      },
      {
        id: 'SC',
        value: 'Santa Catarina',
        selected: address.state === 'SC',
        notSelectable: false,
      },
      {
        id: 'SP',
        value: 'São Paulo',
        selected: address.state === 'SP',
        notSelectable: false,
      },
      {
        id: 'SE',
        value: 'Sergipe',
        selected: address.state === 'SE',
        notSelectable: false,
      },
      {
        id: 'TO',
        value: 'Tocantins',
        selected: address.state === 'TO',
        notSelectable: false,
      },
    ];
  }, [address.state]);

  const handleClickOptions = useCallback(
    (user_id) => {
      if (user_id === showOptionForUser) {
        setShowOptionForUser('');
      } else {
        setShowOptionForUser(user_id);
      }
    },
    [showOptionForUser]
  );

  const handleClickDeleteLead = useCallback(
    (lead) => {
      Swal.fire({
        title: 'Deseja deletar esse solicitação?',
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#e51810',
        cancelButtonColor: '#202020',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            if (lead.id.split('-').length > 1) {
              await api.delete(`leads/${lead.id}`);
            }
            const newleads = leads.filter(
              (leadData) => leadData.id !== lead.id
            );
            setLeads(newleads);

            Toast.fire({
              icon: 'success',
              title: 'Solicitação deletada!',
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [leads]
  );

  const handleClickAddMember = useCallback((lead?: ILead) => {
    if (lead) {
      setLeadSelected(lead);
    }
    setShow(true);
  }, []);

  const columns = useMemo<IColumn[]>(
    () => [
      {
        name: 'Data',
        selector: 'date',
      },
      {
        name: 'Nome',
        selector: 'name',
        cell: (row) => (
          <Username className="d-flex align-items-center">
            <div className="me-2">
              <RiUserFill size={19} color="#00AB64" />
            </div>
            <p className="fw-semibold">{row.name}</p>
          </Username>
        ),
      },
      {
        name: 'Idade',
        selector: 'age',
      },
      {
        name: 'Marca',
        selector: 'brand',
      },
      {
        name: 'E-mail',
        selector: 'email',
      },
      {
        name: 'Número',
        selector: 'size',
      },
      {
        name: '',
        cell: (row: ILead) => (
          <BtnWhatsapp
            href={`https://api.whatsapp.com/send?phone=55${row.celphone}`}
            target="_blank"
            className="btn rounded-pill w-100 py-1"
          >
            <span className="text-white fw-medium me-2">Conversar</span>
            <SiWhatsapp size={18} color="#FFFFFF" />
          </BtnWhatsapp>
        ),
      },
      {
        name: '',
        cell: (row: ILead) => (
          <div className="position-relative d-flex align-items-center justify-content-center mb-0 options-group">
            <button
              type="button"
              className="border-0 bg-transparent"
              onClick={() => handleClickOptions(row.id)}
            >
              <GoKebabVertical size={24} color="#B8B9BA" />
            </button>
            <Options show={showOptionForUser === row.id} className="px-3 py-2">
              <button
                type="button"
                className="border-0 bg-transparent d-flex align-items-center"
                onClick={() => handleClickAddMember(row)}
              >
                <RiUserAddFill size={13} color="#00AB64" className="me-2" />
                <span>Adicionar</span>
              </button>
              <button
                type="button"
                className="border-0 bg-transparent d-flex align-items-center"
                onClick={() => handleClickDeleteLead(row)}
              >
                <HiOutlineTrash size={16} color="#E93131" className="me-2" />
                <span>Excluir</span>
              </button>
            </Options>
          </div>
        ),
      },
    ],
    [
      handleClickAddMember,
      handleClickDeleteLead,
      handleClickOptions,
      showOptionForUser,
    ]
  );

  const handleClose = useCallback(() => {
    setShow(false);
    setLeadSelected(undefined);
    setPlans((state) => {
      return state.map((plan) => {
        if (!plan.id) {
          return {
            ...plan,
            selected: true,
          };
        }
        return {
          ...plan,
          selected: false,
        };
      });
    });
  }, []);

  const handleChangePlan = useCallback((value) => {
    setPlans((state) => {
      return state.map((plan) => {
        if (plan.id === value.id) {
          return {
            ...plan,
            selected: true,
          };
        }
        return {
          ...plan,
          selected: false,
        };
      });
    });
  }, []);

  const handleChangeName = useCallback((e) => {
    setNameError('');
    const nameParts = e.target.value.split(' ');
    if (nameParts.length <= 1 || (nameParts.length > 1 && !nameParts[1])) {
      setNameError('Informe seu nome completo');
    }
  }, []);

  const handleChangeZipCode = useCallback(async (e) => {
    const { value } = e.target;
    if (value.length === 9) {
      const response = await axios.get<IAddressResponse>(
        `https://viacep.com.br/ws/${value}/json/`
      );
      setAddress({
        street: response.data.logradouro,
        neighborhood: response.data.bairro,
        city: response.data.localidade,
        state: response.data.uf,
        complement: response.data.complemento,
      });
    }
  }, []);

  const handleChangeStreet = useCallback(async (e) => {
    const { value } = e.target;
    setAddress((state) => ({ ...state, street: value }));
  }, []);

  const handleChangeNeighborhood = useCallback(async (e) => {
    const { value } = e.target;
    setAddress((state) => ({ ...state, neighborhood: value }));
  }, []);

  const handleChangeCity = useCallback(async (e) => {
    const { value } = e.target;
    setAddress((state) => ({ ...state, city: value }));
  }, []);

  const handleChangeState = useCallback(async (e) => {
    const { value } = e.target;
    setAddress((state) => ({ ...state, state: value }));
  }, []);

  const handleChangeComplement = useCallback(async (e) => {
    const { value } = e.target;
    setAddress((state) => ({ ...state, complement: value }));
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório'),
          email: Yup.string()
            .email('Informe um e-mail válido')
            .required('O e-mail é obrigatório'),
          birthdate: Yup.string().required('O nascimento é obrigatório'),
          celphone: Yup.string().required('O celular é obrigatório'),
          zipcode: Yup.string().required('O CEP é obrigatório'),
          street: Yup.string().required('A rua é obrigatória'),
          number: Yup.string().required('O número é obrigatório'),
          neighborhood: Yup.string().required('O bairro é obrigatório'),
          city: Yup.string().required('A cidade é obrigatória'),
          state: Yup.string().required('O estado é obrigatório'),
          complement: Yup.string().optional(),
          plan: Yup.string().required('O plano é obrigatório'),
          size: Yup.string().required('O tamanho é obrigatório'),
          checkName: Yup.string().when('$checkName', {
            is: (checkName: boolean) => checkName,
            then: Yup.string().required('Informe o nome completo'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            checkName: nameError,
          },
        });

        const {
          name,
          email,
          birthdate,
          celphone,
          zipcode,
          street,
          number,
          neighborhood,
          city,
          state,
          complement,
          plan,
          size,
        } = data;

        const newDate = birthdate.split('/');
        const birthdateData = new Date(
          parseInt(newDate[2], 10),
          parseInt(newDate[1], 10) - 1,
          parseInt(newDate[0], 10)
        );

        const formData = {
          user: { name, email, birthdate: birthdateData, celphone },
          address: {
            zip_code: zipcode,
            street,
            number,
            neighborhood,
            city,
            state,
            complement,
          },
          order: {
            plan_id: plan,
            size,
          },
        };

        const response = await api.post('admins/users', formData);

        if (leadSelected) {
          await api.put(`leads/${leadSelected.id}`, {
            converted: true,
          });

          setLeads((leadsData) =>
            leadsData.filter((lead) => lead.id !== leadSelected.id)
          );
        }

        handleClose();

        Swal.fire({
          icon: 'success',
          title: 'Usuário cadastrado!',
          html: `
          <p>Copie o link a baixo e envie para o usuario para finalização da assinatura</p>
          <div id="url-copy">
            <code>${response.data.url}</code>
            <div class="copy-detail"></div>
          </div>
          `,
          customClass: 'swal-create-user',
          showConfirmButton: false,
        });
        setTimeout(() => {
          setSwalShowed(true);
        }, 300);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationsErrors(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro ao te cadastrar, verifique seus dados.',
            'error'
          );
        }
      }
    },
    [handleClose, leadSelected, nameError]
  );

  return (
    <Container className="py-5">
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-6 order-1 order-lg-0 mb-4 mb-lg-0 d-flex align-items-center">
            <h2 className="fw-semibold mb-0">Olá, {user.name}! ⚽</h2>
          </div>
          <div className="col-lg-6 d-none d-lg-flex justify-content-end pe-lg-5">
            <FloatProfile />
          </div>
        </div>
        <div className="row mt-lg-5">
          <div className="col-lg-5 order-1 mb-3 mb-lg-0">
            <div className="model-box w-100 h-100 p-4 d-flex flex-column justify-content-between">
              <div className="w-100 w-lg-75 mb-5">
                <h5 className="fw-medium w-75">Número de assinaturas</h5>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-n3">
                <div className="w-75">
                  <img
                    src={soccerShoe}
                    alt="soccer shoe"
                    className="w-50 w-lg-25"
                  />
                </div>
                <p className="fw-semibold display-3 mb-0">{subscriptions}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-7 pe-lg-5 order-1">
            <div className="row h-lg-100">
              <div className="col-lg-4 h-100 mb-3 mb-lg-0">
                <div className="box p-4 h-100 d-flex flex-column justify-content-between">
                  <h6 className="fw-semibold mb-5 pe-3">Novos cadastros</h6>
                  <div className="d-flex align-items-center justify-content-between">
                    <RiUserAddFill size={24} color="#00AB64" />
                    <p className="h1 mb-0">+{newUsers}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 h-100 mb-3 mb-lg-0">
                <div className="box p-4 h-100 d-flex flex-column justify-content-between">
                  <h6 className="fw-semibold mb-5 pe-3">Usuários Totais</h6>
                  <div className="d-flex align-items-center justify-content-between">
                    <RiUserFill size={24} color="#00AB64" />
                    <p className="h1 mb-0">{totalUsers}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 h-100 mb-3 mb-lg-0">
                <div className="box p-4 h-100 d-flex flex-column justify-content-between">
                  <h6 className="fw-semibold mb-5 pe-3">Total de planos</h6>
                  <div className="d-flex align-items-center justify-content-between">
                    <TiStarFullOutline size={24} color="#00AB64" />
                    <p className="h1 mb-0">{totalPlans}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12 pe-lg-5">
            <div className="pb-3 my-games">
              <div className="d-flex flex-column flex-lg-row justify-content-between align-items-center mb-3">
                <h2 className="h5 mb-4 order-0 mb-lg-0 fw-semibold">
                  Novas solicitações
                </h2>
                <button
                  type="button"
                  className="btn btn-new rounded-pill px-lg-5 fw-medium d-flex align-items-center justify-content-center mb-0"
                  onClick={() => handleClickAddMember()}
                >
                  <BsPlus size={24} color="#fff" className="me-2" />
                  <span>Novo membro</span>
                </button>
              </div>
            </div>
            <div className="table-content w-100 pt-2">
              <Table
                columns={columns}
                data={leads}
                pagination
                fromData={1}
                toData={3}
                totalData={3}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal size="xl" show={show} onHide={handleClose}>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={leadSelected}>
          <Modal.Header className="border-0" closeButton>
            <h2 className="modal-title fw-semibold h2">
              Cadastrar novo membro
            </h2>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <h4 className="fw-medium mb-4">Dados pessoais</h4>
              </div>
              <label className="col-lg-6 mb-4">
                <span className="fw-medium d-block mb-1">Nome:</span>
                <Input
                  name="name"
                  className="input"
                  onChange={handleChangeName}
                  errorData={nameError}
                />
              </label>
              <label className="col-lg-6 mb-4">
                <span className="fw-medium d-block mb-1">E-mail:</span>
                <Input type="email" name="email" className="input" />
              </label>
              <label className="col-lg-6 mb-4">
                <span className="fw-medium d-block mb-1">
                  Data de nascimento:
                </span>
                <InputMask
                  kind="custom"
                  options={{
                    mask: '99/99/9999',
                  }}
                  name="birthdate"
                  className="input"
                />
              </label>
              <label className="col-lg-6 mb-4">
                <span className="fw-medium d-block mb-1">Celular:</span>
                <InputMask
                  kind="cel-phone"
                  name="celphone"
                  placeholder="(00) 00000-0000"
                  className="input"
                  value={leadSelected?.celphone}
                />
              </label>
              <div className="col-12">
                <hr className="my-4 pb-3" />
                <h4 className="fw-medium mb-4">Endereço</h4>
              </div>
              <label className="col-lg-4 mb-4">
                <span className="fw-medium d-block mb-1">CEP:</span>
                <InputMask
                  kind="zip-code"
                  name="zipcode"
                  className="input"
                  onChange={handleChangeZipCode}
                />
              </label>
              <label className="col-lg-6 mb-4">
                <span className="fw-medium d-block mb-1">Rua:</span>
                <Input
                  name="street"
                  className="input"
                  value={address.street}
                  onChange={handleChangeStreet}
                />
              </label>
              <label className="col-lg-2 mb-4">
                <span className="fw-medium d-block mb-1">Número:</span>
                <Input name="number" className="input" />
              </label>
              <label className="col-lg-4 mb-4">
                <span className="fw-medium d-block mb-1">Bairro:</span>
                <Input
                  name="neighborhood"
                  className="input"
                  value={address.neighborhood}
                  onChange={handleChangeNeighborhood}
                />
              </label>
              <label className="col-lg-4 mb-4">
                <span className="fw-medium d-block mb-1">Cidade:</span>
                <Input
                  name="city"
                  className="input"
                  value={address.city}
                  onChange={handleChangeCity}
                />
              </label>
              <label className="col-lg-4 mb-4">
                <span className="fw-medium d-block mb-1">Estado:</span>
                <Select
                  options={states}
                  name="state"
                  className="input"
                  onChange={handleChangeState}
                />
              </label>
              <label className="col-12 mb-4">
                <span className="fw-medium d-block mb-1">Complemento:</span>
                <Input
                  name="complement"
                  className="input"
                  value={address.complement}
                  onChange={handleChangeComplement}
                />
              </label>
              <div className="col-12">
                <hr className="my-4 pb-3" />
                <h4 className="fw-medium mb-4">Dados complementares</h4>
              </div>
              <label className="col-lg-6 mb-4">
                <span className="fw-medium d-block mb-1">
                  Plano de assinatura:
                </span>
                <Select
                  options={plans}
                  name="plan"
                  className="input"
                  onChange={handleChangePlan}
                />
              </label>
              <label className="col-lg-6 mb-4">
                <span className="fw-medium d-block mb-1">Tamanho:</span>
                <Input type="number" name="size" className="input" />
              </label>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <div className="d-flex align-items-end">
              <button
                type="submit"
                className="btn btn-submit rounded-pill px-lg-5 fw-medium d-flex align-items-center justify-content-center mb-0"
              >
                Salvar
              </button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  );
};

export default Dashboard;
